import { useSelector } from "react-redux";
import { selectors } from "../../redux";
import { MobileFloatingMenu } from "../../components/ApplicationComponents/MobileFloatingMenu/MobileFloatingMenu";
import "./Container.css";
import { RenderAppModals } from "../../components/Modals";
import { LinkHandler } from "../../components/ApplicationComponents/Utilities/LinkHandler";

export const Container = ({
  children,
  handleGoHome,
  activeSub,
  onClickSub,
  onClickThread,
}) => {
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);
  const isDarkMode = useSelector(selectors.selectIsDarkMode);
  const isIndexingSubs = useSelector(selectors.selectSubredditIsIndexing);

  return (
    <div
      style={{
        background: isDarkMode ? "black" : "#f7f7f7",
      }}
      className="container"
    >
      <div className="container-children">{children}</div>
      {!isDesktopMode && !isIndexingSubs && (
        <MobileFloatingMenu
          handleGoHome={handleGoHome}
          activeSub={activeSub}
          onClickSub={onClickSub}
          onClickThread={onClickThread}
        />
      )}
      <RenderAppModals />
      <LinkHandler />
    </div>
  );
};
