import { getFavoritedSubs } from "../../../../helpers/functions";
import { SubList } from "../../../RedditComponents/Subreddit/SubList/SubList";

export const FavoritesList = ({ searchTerm, onClickSub }) => {
  const favs = getFavoritedSubs();
  return (
    <SubList
      filterTerm={searchTerm}
      customList={favs}
      onClickSub={onClickSub}
    />
  );
};
