import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, Search } from "@mui/icons-material";
import { actions } from "../../../redux";
import { useDispatch } from "react-redux";
import { HistoryList } from "./HistoryList/HistoryList";
import { FavoritesList } from "./FavoritesList/FavoritesList";
import { SegmentedControl } from "@mantine/core";
import { SubList } from "../../RedditComponents/Subreddit/SubList/SubList";
import { updateModal } from "../../../redux/slices/application.slice";
import { useState } from "react";
import { ThemeToggleButton } from "../ThemeToggleButton/ThemeToggleButton";

export const MainContentSidebar = () => {
  const style = {
    height: "100vh",
    width: "350px",
    overflow: "hidden",
    boxSizing: "border-box",
    padding: "20px",
  };

  const dispatch = useDispatch();

  const handleClickSub = (sub) => {
    dispatch(actions.setActiveSub(sub));
  };

  const handleToggleCustomThread = () => {
    dispatch(
      updateModal({
        isOpen: true,
        modalId: "customThreadModal",
      })
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  return (
    <Paper>
      <Box sx={style}>
        <Stack sx={{ width: "100%" }}>
          <Stack pb="10px" direction={"row"} spacing={2}>
            <ThemeToggleButton />
            <Tooltip title="Open Custom Link">
              <IconButton onClick={handleToggleCustomThread}>
                <Link />
              </IconButton>
            </Tooltip>
          </Stack>
          <TextField
            fullWidth
            value={searchTerm}
            onChange={({ target }) => setSearchTerm(target.value.toLowerCase())}
            placeholder="Search RedCloud"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <SideBarContent searchTerm={searchTerm} onClickSub={handleClickSub} />
        </Stack>
      </Box>
    </Paper>
  );
};

const SideBarContent = ({ searchTerm, onClickSub }) => {
  const style = {
    marginTop: "10px",
  };

  const contentStyle = {
    height: "calc(100vh - 200px)",
    overflow: "hidden",
  };
  const [value, setValue] = useState("favs");
  const subListSx = {
    paddingBottom: "65px",
  };
  return (
    <Box sx={style}>
      <Stack spacing={2}>
        <SegmentedControl
          onChange={setValue}
          data={[
            { label: "Favorites", value: "favs" },
            { label: "History", value: "history" },
            { label: "All Subs", value: "subs" },
          ]}
        />
        <Box sx={contentStyle}>
          {value === "favs" && (
            <FavoritesList searchTerm={searchTerm} onClickSub={onClickSub} />
          )}
          {value === "history" && <HistoryList />}
          {value === "subs" && (
            <SubList filterTerm={searchTerm} onClickSub={onClickSub} />
          )}
        </Box>
      </Stack>
    </Box>
  );
};
