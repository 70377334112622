import "./MobileFloatingMenu.css";
import { AddLink, Home, ViewList } from "@mui/icons-material";
import { IconButton, Paper, SwipeableDrawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MainContentSidebar } from "../MainContentSidebar/MainContentSidebar";
import { selectors } from "../../../redux";
import {
  toggleCustomThreadModal,
  updateModal,
} from "../../../redux/slices/application.slice";
import { useState } from "react";
import { ThemeToggleButton } from "../ThemeToggleButton/ThemeToggleButton";

export const MobileFloatingMenu = ({ handleGoHome }) => {
  const [isSubDrawerOpen, setIsSubDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);
  const isDarkMode = useSelector(selectors.selectIsDarkMode);

  const handleCloseSubMenu = () => setIsSubDrawerOpen(false);
  const handleToggleCustomThread = () => {
    dispatch(
      updateModal({
        isOpen: true,
        modalId: "customThreadModal",
      })
    );
  };
  return (
    <Paper
      sx={{
        background: isDarkMode
          ? `linear-gradient(
    360deg,
    rgb(0 0 0),
    rgb(0 0 0 / 20%)
  )`
          : `linear-gradient(
    360deg,
    rgb(255 255 255 / 85%),
    rgb(255 255 255 / 60%)
  )`,
      }}
      className="menu"
    >
      <IconButton onClick={handleGoHome}>
        <Home />
      </IconButton>
      <ThemeToggleButton />
      <IconButton onClick={handleToggleCustomThread}>
        <AddLink />
      </IconButton>
      <IconButton
        disabled={isDesktopMode}
        onClick={() => setIsSubDrawerOpen(true)}
      >
        <ViewList />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={isSubDrawerOpen}
        onClose={handleCloseSubMenu}
        onOpen={() => {}}
        keepMounted
      >
        <MainContentSidebar />
      </SwipeableDrawer>
    </Paper>
  );
};
