// Open a connection to the indexedDB
export function openSubredditsIndexedDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("subreddits", 1);

    request.onerror = (event) => {
      reject("Error opening database");
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const objectStore = db.createObjectStore("subreddits");
    };
  });
}

// Save a subreddit object to the indexedDB
export async function saveSubredditToIndexedDB(subreddit) {
  const db = await openSubredditsIndexedDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction("subreddits", "readwrite");
    const objectStore = transaction.objectStore("subreddits");

    const request = objectStore.put(
      subreddit,
      subreddit.details.name.toLowerCase()
    );

    request.onerror = () => {
      reject("Error saving subreddit");
    };

    request.onsuccess = () => {
      resolve("Subreddit saved successfully");
    };
  });
}

// Retrieve a subreddit object from the indexedDB by name
export async function getSubredditFromIndexedDB(_name) {
  const name = _name.toLowerCase();

  const db = await openSubredditsIndexedDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction("subreddits", "readonly");
    const objectStore = transaction.objectStore("subreddits");

    const request = objectStore.get(name);

    request.onerror = () => {
      reject("Error retrieving subreddit");
    };

    request.onsuccess = (event) => {
      const subreddit = event.target.result;
      resolve(subreddit);
    };
  });
}
