import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SkeletonLoaderPreset } from "../../../ApplicationComponents/SkeletonLoaderPreset/SkeletonLoaderPreset";
import { getSimilarSubreddits } from "../../../../api/base";
import { ErrorMessageDisplay } from "../../../ApplicationComponents/ErrorMessageDisplay/ErrorMessageDisplay";
import { SubCardList } from "../SubCardList/SubCardList";
import { useDispatch } from "react-redux";
import { updateModal } from "../../../../redux/slices/application.slice";
import { SubLiveCard } from "../SubLiveCard/SubLiveCard";

const subCardStyles = {
  width: "280px",
  flexShrink: 0,
  borderRadius: "15px",
};
export const SubSimilarSubreddits = ({ sub, hideLabel = false }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const fetchSimilarSubs = () => {
    setIsLoading(true);
    setError(false);
    setData([]);

    getSimilarSubreddits(sub)
      .then((data) => {
        setIsLoading(false);
        console.log("Got similar subs: ", data);
        if (data?.to && data?.to?.length) {
          setData(data.to);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(true);
      });
  };
  useEffect(() => {
    fetchSimilarSubs();
  }, [sub]);
  if (!sub) return null;
  return (
    <Stack>
      {!isLoading && error && (
        <ErrorMessageDisplay
          message={"Unable to get subs"}
          onRetry={fetchSimilarSubs}
        />
      )}
      {!hideLabel && (
        <Typography variant={"caption"}>Similar Subreddits:</Typography>
      )}
      {isLoading && <SkeletonLoaderPreset />}
      {!isLoading && !error && data && (
        <SubCardList
          useOldCard={false}
          subArray={data}
          SubCardProps={{ style: subCardStyles }}
        />
      )}
    </Stack>
  );
};
