import { Box } from "@mui/material";
import { SubChip } from "../../Subreddit/SubChip/SubChip";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../../redux/selectors/appState.selectors";

export const PostSub = ({ onClick, thread }) => {
  const isDarkMode = useSelector(selectIsDarkMode);

  return (
    <Box className="post-sub-container">
      <Box className="post-subreddit">
        <SubChip
          onClick={onClick}
          sub={thread.subreddit}
          TypographySx={{
            color: isDarkMode ? "white" : "black",
            fontSize: "10px",
          }}
          SubIconSx={{
            width: "20px",
          }}
        />
      </Box>
    </Box>
  );
};
