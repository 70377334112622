import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ContentCopy,
  ExpandLess,
  MoreHoriz,
  MoreVert,
  SettingsEthernet,
  UnfoldLess,
} from "@mui/icons-material";
import { useState } from "react";
import { AuthorAvatar } from "../../Profile/AuthorAvatar/AuthorAvatar";
import { useDispatch } from "react-redux";
import { updateModal } from "../../../../redux/slices/application.slice";
import { getAuthorFlairProps } from "../../../../helpers/functions";
import { getCommentData } from "../commentHelper";

export const CommentMenu = ({ comment, onCollapse }) => {
  const dispatch = useDispatch();
  const commentData = getCommentData(comment);

  const { author, body, body_html, created_utc, ups, replies, parent_id, id } =
    comment;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleOpenJSONModal = (raw = true) => {
    dispatch(
      updateModal({
        modalId: "jsonModal",
        isOpen: true,
        data: raw ? comment : commentData,
      })
    );
  };

  const handleOpenProfileModal = () => {
    dispatch(
      updateModal({
        modalId: "profileModal",
        isOpen: true,
        data: {
          username: author,
          flair: getAuthorFlairProps(comment),
          subreddit: comment?.subreddit,
        },
      })
    );
  };

  return (
    <>
      <IconButton
        id={`comment-menu-id-${id}`}
        aria-controls={open ? "comment-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size={"small"}
      >
        {!open && <MoreHoriz />}
        {open && <ExpandLess />}
      </IconButton>
      <Menu
        id={`comment-menu-popper-id-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `comment-menu-id-${id}`,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={(event) => {
            handleOpenProfileModal();
            handleClose(event);
          }}
        >
          <ListItemIcon>
            <AuthorAvatar
              username={author}
              showOnlyProfileImage
              imgProps={{
                style: {
                  width: "25px",
                  height: "25px",
                },
              }}
            />
          </ListItemIcon>
          <ListItemText>View Profile</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            navigator.clipboard.writeText(body).then((r) => {
              handleClose(event);
            });
          }}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Copy Text</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleClose(event);
            onCollapse();
          }}
        >
          <ListItemIcon>
            <UnfoldLess />
          </ListItemIcon>
          <ListItemText>Collapse</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            // TODO: Implement JSON Modal
            handleOpenJSONModal();
            handleClose(event);
          }}
        >
          <ListItemIcon>
            <SettingsEthernet />
          </ListItemIcon>
          <ListItemText>View API JSON</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            // TODO: Implement JSON Modal
            handleOpenJSONModal(false);
            handleClose(event);
          }}
        >
          <ListItemIcon>
            <SettingsEthernet />
          </ListItemIcon>
          <ListItemText>View Parsed Data</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
