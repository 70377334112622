import { CircularProgress, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../../redux/selectors/appState.selectors";
import { selectSubredditIndexingProgress } from "../../../redux/selectors/appState.selectors";
import { styleHelper } from "../../../styles/styles";

const ActiveSub = () => {
  const currentSubFetching = useSelector(
    selectors.selectSubredditData
  ).currentSubToFetch;

  return (
    <Typography
      sx={{
        fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      {currentSubFetching}
    </Typography>
  );
};
export const SubIndexerLoader = () => {
  return (
    <Stack sx={{ alignItems: "center", gap: "10px", marginTop: "20px" }}>
      <ProgressDisplay />
      <ActiveSub />
      <Typography
        sx={{
          fontFamily: styleHelper.EFontFamily.CoinbaseText,
          textAlign: "center",
          opacity: 0.5,
          fontSize: "0.75rem",
        }}
      >
        Indexing subreddits
      </Typography>
    </Stack>
  );
};

const ProgressDisplay = () => {
  const progress = useSelector(selectors.selectSubredditIndexingProgress);

  return (
    <Stack sx={{ position: "relative", width: "fit-content" }}>
      <CircularProgress
        variant={"determinate"}
        value={progress}
        size={"30vw"}
        sx={{ color: getColor(progress), transition: "color 0.1s ease-in-out" }}
      />
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
            color: getColor(progress),
            fontWeight: "bold",
          }}
        >
          {progress}%
        </Typography>
      </Stack>
    </Stack>
  );
};

const getColor = (progress = 0) => {
  if (progress < 10) return colorMap.begin;
  if (progress < 30) return colorMap.middle1;
  if (progress < 50) return colorMap.middle2;
  if (progress < 75) return colorMap.almost;
  return colorMap.done;
};

const colorMap = {
  begin: "#fa5252",
  middle1: "#e64980",
  middle2: "#228be6",
  almost: "#fab005",
  done: "#40c057",
};
