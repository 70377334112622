import { Stack } from "@mui/material";
import { SubCard } from "../SubCard/SubCard";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../../../redux/slices/application.slice";
import { selectModalState } from "../../../../redux/selectors/appState.selectors";
import { SubLiveCard } from "../SubLiveCard/SubLiveCard";

export const SubCardList = ({
  subArray = [],
  SubCardProps = {},
  useOldCard = true,
}) => {
  const dispatch = useDispatch();
  const isSubModalOpen = useSelector(selectModalState)["subredditModal"].isOpen;

  const openSubModal = (sub) => {
    dispatch(
      updateModal({
        modalId: "subredditModal",
        isOpen: true,
        data: {
          sub,
        },
      })
    );
  };
  const handleClickSub = (sub) => {
    if (isSubModalOpen) {
      dispatch(
        updateModal({
          modalId: "subredditModal",
          isOpen: false,
          data: null,
        })
      );

      setTimeout(() => openSubModal(sub), 500);
    } else {
      openSubModal(sub);
    }
  };
  if (useOldCard) {
    return (
      <Stack
        sx={{
          padding: "10px 0px",
          gap: "20px",
          width: "100%",
          overflowY: "auto",
          flexWrap: "nowrap",
        }}
        direction={"row"}
      >
        {subArray.map((sub) => (
          <SubCard
            onClick={() => handleClickSub(sub)}
            sub={sub}
            key={sub}
            {...SubCardProps}
          />
        ))}
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        padding: "10px 0px",
        gap: "20px",
        width: "100%",
        overflowY: "auto",
        flexWrap: "nowrap",
      }}
      direction={"row"}
    >
      {subArray.map((sub) => (
        <SubLiveCard onClick={() => handleClickSub(sub)} sub={sub} key={sub} />
      ))}
    </Stack>
  );
};
