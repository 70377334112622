import { Box, Portal } from "@mui/material";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/slices/application.slice";
import { updateModal } from "../../../redux/slices/application.slice";
import { openURLInNewTab } from "../../../helpers/functions";

export const LinkHandler = () => {
  const dispatch = useDispatch();

  const handleRouteToSub = (sub) => {
    let subreddit = sub;
    if (sub.startsWith("/r/")) {
      subreddit = sub.slice(3);
    }
    if (sub.startsWith("r/")) {
      subreddit = sub.slice(2);
    }
    // Close the current thread
    dispatch(actions.setActiveThread(null));

    // Close the current Sub Drawer
    dispatch(actions.setActiveSub(null));

    // Close any modals
    dispatch(actions.closeAllModals());

    // Open the new sub
    setTimeout(() => {
      console.log("Routing to sub: ", subreddit);
      dispatch(actions.setActiveSub(subreddit));
    }, 500);
  };

  const handleRouteToUser = (user) => {
    let username = user;
    if (user.startsWith("/u/")) {
      username = username.slice(3);
    }
    if (user.startsWith("u/")) {
      username = username.slice(2);
    }
    dispatch(
      updateModal({
        modalId: "profileModal",
        isOpen: true,
        data: { username },
      })
    );
  };

  const handleParseLinkAndRoute = () => {
    try {
      const { value } = document.getElementById("hidden-link-handler-input");
      if (value) {
        if (isSubLink(value)) {
          console.log("Detected a subreddit link");
          handleRouteToSub(value);
          return;
        }

        if (isUserLink(value)) {
          console.log("Detected a user link");
          handleRouteToUser(value);
          return;
        }

        if (isRedditThreadLink(value)) {
          console.log("Detected a Reddit thread link!");
        }
        console.log("Detected an outbound link: ", value);
        openURLInNewTab(value);
      } else {
        console.log("No value!");
      }
    } catch (e) {
      console.log("Failed to parse link for routing");
    }
  };
  return (
    <Portal>
      <Box sx={hiddenStyles}>
        <input id={"hidden-link-handler-input"} />
        <button
          id={"hidden-link-handler-submit"}
          onClick={handleParseLinkAndRoute}
        >
          check link
        </button>
      </Box>
    </Portal>
  );
};

const hiddenStyles = {
  background: "white",
  position: "fixed",
  top: "0",
  left: "0",
  zIndex: "-9999999",
  opacity: "0",
  transform: "scale(0.0005)",
};

function isSubLink(inputString) {
  const regex = /^(\/)?r\/[^/]*$/; // Matches "/r/" followed by any non-slash characters until the end of the string
  return regex.test(inputString);
}

function isUserLink(inputString) {
  const regex = /^(\/)?u\/[^/]*$/; // Matches "/r/" followed by any non-slash characters until the end of the string
  return regex.test(inputString);
}

function isRedditThreadLink(inputString) {
  const regex =
    /^https?:\/\/www\.reddit\.com\/r\/[^/]+\/comments\/[^/]+\/[^/]+\/$/;
  return regex.test(inputString);
}
