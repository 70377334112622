import { getCommentData } from "../commentHelper";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../../redux/selectors/appState.selectors";
import { uiSelectors } from "../../../../redux";
import { parseInjectHTMLBeforeRender } from "../../../../helpers/HTML";
import { blockquoteStyles, codeStyles } from "../../../../styles/styles";

export const CommentBodyHTML = ({ comment }) => {
  // Get Comment Props
  const commentData = getCommentData(comment);
  const isDarkMode = useSelector(selectIsDarkMode);
  const fontSize = useSelector(uiSelectors.global.selectComment).fontSize;

  return (
    <Box
      className={"comment-body-injected-html"}
      sx={{
        fontSize,
        overflowX: "hidden",
        ...blockquoteStyles(isDarkMode),
        ...codeStyles(isDarkMode),
      }}
      dangerouslySetInnerHTML={{
        __html: !commentData.bool.isEmpty
          ? parseInjectHTMLBeforeRender(commentData.body.HTML)
          : "",
      }}
    ></Box>
  );
};

/*

 * */
