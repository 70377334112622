import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { STATIC_ASSET_URL } from "../../../../config";
import { fetchSubredditData } from "../../../../helpers/functions";

const DEFAULT_ICON = "https://static.anthony.media/icons/reddit.png";

export const SubIcon = ({ sub, sx = {}, overrideIcon = "", ImgProps = {} }) => {
  const [url, setUrl] = useState(DEFAULT_ICON);

  useEffect(() => {
    fetchSubredditData(sub)
      .then((data) => {
        console.log("data: ", data.details.artwork.icon);

        setUrl(data.details.artwork.icon);
      })
      .catch((err) => {
        console.log(`SubIcon Error: sub r/${sub}`);
        console.log(err);
      });
  }, [sub]);

  if (!sub) return null;
  return (
    <Box
      sx={{
        height: "25px",
        display: "flex",
        paddingLeft: "5px",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <img
        onError={() => setUrl(DEFAULT_ICON)}
        src={url}
        draggable="false"
        style={{ width: "35px", borderRadius: "5px" }}
        {...ImgProps}
      />
    </Box>
  );
};

export const _SubIcon = ({
  sub,
  sx = {},
  ImgProps = {},
  overrideIcon = "",
}) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  if (!sub) return null;
  let staticURL = `${STATIC_ASSET_URL}/reddit/icons/${sub.toLowerCase()}.png`;
  if (overrideIcon) {
    staticURL = overrideIcon;
  }
  return (
    <Box
      sx={{
        height: "25px",
        display: "flex",
        paddingLeft: "5px",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      {isError && (
        <img
          draggable={"false"}
          src={DEFAULT_ICON}
          style={{ width: "25px", borderRadius: "50%" }}
          {...ImgProps}
        />
      )}
      {isLoading && <CircularProgress size={15} />}
      {!isError && (
        <img
          draggable={"false"}
          onError={() => {
            setIsError(true);
            setIsLoading(false);
          }}
          onLoad={() => setIsLoading(false)}
          style={{ width: "35px", borderRadius: "5px" }}
          src={staticURL}
          {...ImgProps}
        />
      )}
    </Box>
  );
};
