import { ESETTINGS, settingsRedux } from "../slices/settings.slice";

const NAMESPACE = settingsRedux.namespace;

export const uiSelectors = {
  global: {
    selectAwards: (state) =>
      state[NAMESPACE].global[ESETTINGS.UI.label][
        ESETTINGS.UI.category.awards.label
      ],
    selectPost: (state) =>
      state[NAMESPACE].global[ESETTINGS.UI.label][
        ESETTINGS.UI.category.post.label
      ],
    selectComment: (state) =>
      state[NAMESPACE].global[ESETTINGS.UI.label][
        ESETTINGS.UI.category.comment.label
      ],
  },
};
