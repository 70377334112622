import React from "react";
import ReactDOM from "react-dom/client";
import App from "./root/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { selectors } from "./redux";
import { THEME_MODES } from "./config";
import { MantineProvider } from "@mantine/core";

const lightTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const AppTheme = () => {
  const userTheme = useSelector(selectors.selectTheme);

  return (
    <MantineProvider
      theme={{
        colorScheme:
          userTheme === THEME_MODES.light
            ? THEME_MODES.light
            : THEME_MODES.dark,
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <ThemeProvider
        theme={userTheme === THEME_MODES.light ? lightTheme : darkTheme}
      >
        <CssBaseline />
        <App />
      </ThemeProvider>
    </MantineProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppTheme />
    </Provider>
  </React.StrictMode>
);

//serviceWorkerRegistration.register();
