import { Box, Stack, Typography } from "@mui/material";
import { getThreadGallery } from "../../../../helpers/functions";
import { Image } from "@mui/icons-material";
import { styleHelper } from "../../../../styles/styles";

export const ThreadGallery = ({ gallery }) => {
  return (
    <Box>
      <Stack>
        {gallery.images.map((image, index) => {
          const { url, caption } = image;

          return (
            <Stack
              sx={(theme) => {
                return {
                  "& a": {
                    color: `${theme.palette.primary.main}!important`,
                    textDecoration: "none",
                  },
                };
              }}
              key={index}
            >
              <a href={url} target={"_blank"} rel={"noreferrer nofollow"}>
                <img
                  draggable={"false"}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    cursor: "pointer",
                    "&: hover": {
                      opacity: 0.75,
                    },
                  }}
                  src={url}
                />
                <Typography
                  sx={{
                    fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  {caption ?? "-"}
                </Typography>
              </a>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};
