import { Box, Divider, Stack } from "@mui/material";
import { Comment } from "../Comment";
import { FullWidthLoader } from "../../../Loaders/FullWidthLoader/FullWidthLoader";

export const CommentRenderer = ({ comments, isLoading, opUsername }) => {
  const renderComments = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {comments.map((comment, index) => (
          <Stack sx={{ gap: "15px" }} key={index}>
            <Comment comment={comment.data} opUsername={opUsername} />
            <Divider />
          </Stack>
        ))}
      </div>
    );
  };
  if (isLoading) {
    return <FullWidthLoader />;
  }
  if (!comments)
    return (
      <div>
        <p>No comments yet</p>
      </div>
    );
  return <div>{renderComments()}</div>;
};
