import { Box, CircularProgress, Skeleton, Stack } from "@mui/material";

export const VideoLoader = () => {
  return (
    <Stack sx={{ position: "relative" }}>
      <Skeleton sx={{ transform: "none" }} width={"100%"} height={"250px"} />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <CircularProgress size={75} style={{ color: "#e2e2e2" }} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "45px",
          left: "25px",
          width: "100%",
        }}
      >
        <Skeleton width={"50px"} height={"50px"} variant={"circular"} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "25px",
          left: 0,
          width: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Skeleton width={"88%"} height={"10px"} />
      </Box>
    </Stack>
  );
};
