import { createSlice } from "@reduxjs/toolkit";

export const ESETTINGS = {
  UI: {
    label: "UI",
    category: {
      awards: {
        label: "awards",
        defaultValues: {
          isEnabled: true,
        },
      },
      post: {
        label: "post",
        defaultValues: {
          fontSize: "1rem",
        },
      },
      comment: {
        label: "comment",
        defaultValues: {
          fontSize: "15px",
        },
      },
    },
  },
};

const getUIState = () => ({
  [ESETTINGS.UI.label]: {
    [ESETTINGS.UI.category.awards.label]: {
      isEnabled: ESETTINGS.UI.category.awards.defaultValues.isEnabled,
    },
    [ESETTINGS.UI.category.post.label]: {
      fontSize: ESETTINGS.UI.category.post.defaultValues.fontSize,
    },
    [ESETTINGS.UI.category.comment.label]: {
      fontSize: ESETTINGS.UI.category.comment.defaultValues.fontSize,
    },
  },
});

const initialState = {
  global: {
    ...getUIState(),
  },
  subRedditSettings: {},
};

const name = "settings";

export const settingsSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateUI: (state, payload) => {
      const { settingCategory, settingKey, value } = payload;
      state.userInterface[settingCategory][settingKey] = value;
    },
  },
});

export const settingsRedux = {
  actions: settingsSlice.actions,
  reducer: settingsSlice.reducer,
  namespace: name,
};
