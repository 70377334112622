const linkCode = `
(function handleHTMLLinkClick(event) {
  event.preventDefault();
  console.log('a link was clicked!', event)
  try {
    const { href } = event.target;
    
    let newValue = href;
    if(!newValue){
      console.log('Target: ', event.target)
      throw(Error('Link is empty'))
      return
    }
    if(newValue.startsWith('http://' + window.location.host)) {
      newValue = newValue.replace('http://' + window.location.host, '');
    }
    if(newValue.startsWith('https://' + window.location.host)) {
      newValue = newValue.replace('https://' + window.location.host, '');
    }
    
    
    document.getElementById('hidden-link-handler-input').value = newValue;
    document.getElementById('hidden-link-handler-submit').click();
  } catch(e) {
    console.log('RedCloud: Failed to handle link click logic.', e)
  }
})(event)
`;
function addTargetBlank(htmlString) {
  const regex = /<a\s+(.*?)>/gi;
  const replacement = `<a $1 target="_blank" onclick="${linkCode}">`;
  const editedHtml = htmlString.replace(regex, replacement);
  return editedHtml;
}

function getEmbeddedImages(htmlString) {
  // Define a regex to match URLs that start with "https://preview.redd.it"
  const imageRegex = /https:\/\/preview\.redd\.it\/[^\s"]+/gi;
  const images = [];

  // Find all matches for the image URLs
  let match;
  while ((match = imageRegex.exec(htmlString)) !== null) {
    images.push(match[0]);
    console.log("Image found:", match[0]); // Console log each image URL
  }

  // Remove duplicates by casting to a Set, then back to an array
  const uniqueImages = Array.from(new Set(images));

  return uniqueImages.filter((a) => !a.includes("</a></p>"));
}

const getImageHTML = (images) => {
  const _testImg =
    "https://i.ebayimg.com/images/g/n8IAAOSwltRkNCSF/s-l1200.png";
  return `
    <p style="font-size: x-small; font-weight: bold; opacity: 0.5; margin: 0; padding-left: 15px;font-style: italic;">${
      images.length
    } Image${images.length === 1 ? "" : "s"}</p>
    <div style="max-width: 98%">
    ${images
      .map(
        (image) =>
          `<img src="${image}" style="width: 100%; border-radius: 10px; box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; transform: scale(0.97)"/>`
      )
      .join(" ")}
</div>
    `;
};

export const parseInjectHTMLBeforeRender = (htmlString) => {
  if (!htmlString) return "";
  let newHTML = htmlString;

  // Add image detection and count
  const images = getEmbeddedImages(newHTML);

  // Check for anchor tag links
  newHTML = addTargetBlank(newHTML);

  if (images.length) {
    newHTML += getImageHTML(images);
  }

  return newHTML;
};

export function decodeHTMLEntities(input) {
  const entityMap = {
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&quot;": '"',
    "&#39;": "'",
    "&#x2F;": "/",
    "&#x60;": "`",
    "&#x3D;": "=",
    "&#x20;": " ",
    // Add more entity mappings as needed
  };

  let htmlString = input.replace(
    /&(lt|gt|amp|quot|#39|#x2F|#x60|#x3D|#x20);/g,
    (match) => entityMap[match]
  );

  return htmlString;
}
