import "./SubQuickActions.css";
import SortByAlpha from "@mui/icons-material/SortByAlpha";
import KeyboardDoubleArrowUp from "@mui/icons-material/KeyboardDoubleArrowUp";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import Sort from "@mui/icons-material/Sort";
import Circle from "@mui/icons-material/Circle";
import Check from "@mui/icons-material/Check";
import { useState } from "react";
import { SORT_METHOD_KEYS } from "../../../../helpers/functions";
export const SubQuickActions = ({ onChangeSort, sortMethod }) => {
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const isSortMenuOpen = Boolean(sortAnchorEl);
  const isSortActive = {
    [SORT_METHOD_KEYS.DEFAULT]: sortMethod === SORT_METHOD_KEYS.DEFAULT,
    [SORT_METHOD_KEYS["VOTES_ASCENDING"]]:
      sortMethod === SORT_METHOD_KEYS["VOTES_ASCENDING"],
  };

  const handleChangeSort = (str) => {
    setSortAnchorEl(null);
    onChangeSort(str);
  };

  const ActiveCheck = ({ isActive }) => {
    return (
      isSortActive[isActive] && (
        <span style={{ marginLeft: "auto", paddingLeft: "10px" }}>
          <Check sx={{ color: "green" }} />
        </span>
      )
    );
  };
  return (
    <div className="sub-quick-actions">
      <IconButton onClick={(e) => setSortAnchorEl(e.currentTarget)}>
        <SortByAlpha />
      </IconButton>
      <Menu
        anchorEl={sortAnchorEl}
        open={isSortMenuOpen}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          onClick={() => handleChangeSort(SORT_METHOD_KEYS.DEFAULT)}
          sx={{ color: isSortActive[SORT_METHOD_KEYS.DEFAULT] && "green" }}
        >
          <ListItemIcon>
            <Sort />
          </ListItemIcon>
          Default
          <ActiveCheck isActive={SORT_METHOD_KEYS.DEFAULT} />
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeSort(SORT_METHOD_KEYS["VOTES_ASCENDING"])}
          sx={{
            color: isSortActive[SORT_METHOD_KEYS["VOTES_ASCENDING"]] && "green",
          }}
        >
          <ListItemIcon>
            <KeyboardDoubleArrowUp />
          </ListItemIcon>
          Most Upvoted
          <ActiveCheck isActive={SORT_METHOD_KEYS["VOTES_ASCENDING"]} />
        </MenuItem>
      </Menu>
    </div>
  );
};
