import { Typography } from "@mui/material";

export const GradientText = ({
  gradientString = "",
  color1 = "#FFF",
  color2 = "#000",
  angle = "180deg",
  sx = {},
  children,
  TypographyProps = {},
}) => {
  const gradientStyle = {
    background: gradientString
      ? gradientString
      : `-webkit-linear-gradient(${angle}, ${color2} 0%, ${color1} 70%)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
  return (
    <Typography sx={{ ...sx, ...gradientStyle }} {...TypographyProps}>
      {children}
    </Typography>
  );
};
