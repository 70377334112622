import { dayOfWeek, timeStringClean } from "../../../../helpers/functions";

export const PostDate = ({ thread }) => {
  const { created_utc, stickied } = thread;

  const time = new Date(created_utc * 1000);

  const Day = () => {
    return (
      <span className="post-date-day">
        {time.getMonth() + 1}/{time.getDate()} - {dayOfWeek(time)}
      </span>
    );
  };
  const FullDate = () => {
    return (
      <span className="post-date-full-date">{time.toLocaleDateString()}</span>
    );
  };
  return (
    <div className="post-date">
      {stickied ? <FullDate /> : <Day />}
      <span className="post-date-time">{timeStringClean(time)}</span>
    </div>
  );
};
