import { stringToColour } from "../../../../helpers/functions";
import { ButtonBase, Paper, Stack, Typography } from "@mui/material";
import { AuthorFlair } from "../AuthorFlair/AuthorFlair";
import { styleHelper } from "../../../../styles/styles";
import { GradientText } from "../../../ApplicationComponents/GradientText/GradientText";
import { useDispatch, useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../../redux/selectors/appState.selectors";
import { updateModal } from "../../../../redux/slices/application.slice";

const avatarURL = "https://api.dicebear.com/6.x/bottts/svg?seed=";
const ignoreList = ["[deleted]"];

export const AuthorAvatar = ({
  username,
  hideUsername = false,
  hideProfileImage = false,
  showOnlyProfileImage = false,
  isOP = false,
  imgProps = {},
  onClick = () => {},
  size = "30px",
  flair = null,
  AuthorFlairProps = {},
  TypographyProps = {},
  GradientTextProps = {},
  openProfileModalOnClick = false,
  subreddit = "",
  authorFlair,
  sx = {},
}) => {
  const dispatch = useDispatch();
  const hasFlair =
    !!flair &&
    flair?.author_flair_text?.trim() &&
    flair?.author_flair_text != "\u200B";

  const isDarkMode = useSelector(selectIsDarkMode);
  const isAutoMod = username === "AutoModerator";
  const imgURL = isAutoMod
    ? "/static/images/automoderator.png"
    : avatarURL + username;
  const handleClick = (event) => {
    event.stopPropagation();
    setTimeout(() => {
      onClick();
      if (openProfileModalOnClick) {
        dispatch(
          updateModal({
            modalId: "profileModal",
            data: {
              username: username,
              flair: AuthorFlairProps,
              subreddit: subreddit ? subreddit : "",
            },
            isOpen: true,
          })
        );
      }
    }, 300);
  };

  const AvatarImage = (props) => (
    <img
      {...props}
      draggable={"false"}
      style={{
        width: size,
        height: size,
        pointerEvents: "none",
        ...(props?.style ? props.style : {}),
      }}
      src={imgURL}
    />
  );

  if (ignoreList.includes(username)) return null;

  if (showOnlyProfileImage) return <AvatarImage {...imgProps} />;
  return (
    <ButtonBase
      component={"div"}
      sx={{
        borderRadius: "20px",
        ...sx,
      }}
      onClick={handleClick}
    >
      <Paper
        sx={{
          padding: "2px",
          "&:hover": {
            opacity: 0.75,
          },
          userSelect: "none",
          cursor: "pointer",
          background: "transparent",
          boxShadow: "none",
          border: "0px",
        }}
      >
        <Stack gap={"6px"} direction={"row"} alignItems={"center"}>
          {!hideProfileImage && <AvatarImage {...imgProps} />}
          {!hideUsername && (
            <Stack gap={"5px"} alignItems={"center"} direction={"row"}>
              <GradientText
                color1={isDarkMode ? "#FFF" : "#000"}
                color2={stringToColour(username)}
                sx={{
                  userSelect: "none",
                  // textShadow: "2px 2px 0px rgba(0, 0, 0, 0.1)",
                }}
                TypographyProps={{
                  fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
                  fontWeight: 700,
                  fontSize: hasFlair ? "small" : undefined,
                  pointerEvents: "none",
                  ...TypographyProps,
                }}
                {...GradientTextProps}
              >
                {username}
              </GradientText>
              {hasFlair && (
                <AuthorFlair
                  sx={{
                    overflowX: "scroll",
                    flexWrap: "nowrap",
                    //alignItems: "flex-start",
                    "& > p": {
                      fontSize: "0.35rem",
                      textAlign: "center",
                      padding: "1px 5px",
                    },
                  }}
                  flair={flair}
                  {...AuthorFlairProps}
                />
              )}
            </Stack>
          )}
          {isOP && (
            <span
              style={{
                fontWeight: 700,
                color: "red",
                userSelect: "none",
                fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
              }}
            >
              {" "}
              OP
            </span>
          )}
        </Stack>
      </Paper>
    </ButtonBase>
  );
};
