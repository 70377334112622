import { Favorite, FavoriteBorder, LogoDev } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getSubJSONData,
  isSubFavorited,
  stringToColour,
  toggleFavoriteSub,
} from "../../../../helpers/functions";
import { SubBackground } from "../SubBackground/SubBackground";
import { SubIcon } from "../SubIcon/SubIcon";
import "./SubCard.css";
import { styleHelper } from "../../../../styles/styles";

export const SubCard = ({
  sub,
  onClick = () => {},
  style = {},
  showAddToFavorites = false,
}) => {
  const [slimCard, setSlimCard] = useState(false);

  const [isFavorited, setIsFavorited] = useState(false);
  const handleToggleFavorite = () => {
    setIsFavorited((prev) => !prev);
    toggleFavoriteSub(sub);
  };

  const height = slimCard ? "" : "150px";
  const color = slimCard ? "black" : "";
  const subJSON = getSubJSONData(sub);

  useEffect(() => {
    if (!sub) return;

    if (isSubFavorited(sub)) {
      setIsFavorited(true);
    }
  }, []);

  if (!sub) return null;
  return (
    <Box
      sx={{
        background: `linear-gradient(${Math.round(360 * Math.random())}deg, ${
          stringToColour(sub) + "55"
        }, transparent)`,
        height,
        color,
        ...style,
      }}
      className={`subreddit-display-card ${slimCard ? "slim" : ""}`}
      onClick={onClick}
    >
      <div className="icon-label-container">
        <SubIcon
          sx={{ marginRight: "10px" }}
          ImgProps={{
            style: {
              width: "50px",
              borderRadius: "10px",
            },
          }}
          sub={sub}
        />
        <Stack>
          <span
            style={{
              fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
              lineHeight: "25px",
            }}
          >
            {sub}
          </span>
          <Typography
            sx={{
              color: "#959595",
              fontFamily: `'CoinbaseText', sans-serif`,
              lineHeight: "15px",
            }}
            variant="caption"
          >
            {subJSON.slogan}
          </Typography>
        </Stack>
        {showAddToFavorites && (
          <IconButton
            sx={{
              marginLeft: "10px",
              backdropFilter: "blur(20px) saturate(1.5)",
              background: "rgb(255 255 255 / 25%)",
            }}
            onClick={handleToggleFavorite}
          >
            {!isFavorited && <FavoriteBorder />}
            {isFavorited && <Favorite sx={{ color: "#c70808" }} />}
          </IconButton>
        )}
      </div>
      {!slimCard && (
        <>
          <div className="background-container">
            <SubBackground
              onError={() => setSlimCard(true)}
              sub={sub}
              variant={2}
            />
          </div>
          <div className="shadow-overlay"></div>
        </>
      )}
    </Box>
  );
};
