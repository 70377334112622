import { Box, Typography, Stack, Skeleton, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectIsDarkMode,
  selectModalState,
} from "../../../redux/selectors/appState.selectors";
import { AuthorAvatar } from "../../RedditComponents/Profile/AuthorAvatar/AuthorAvatar";
import { SubIcon } from "../../RedditComponents/Subreddit/SubIcon/SubIcon";
import { AuthorFlair } from "../../RedditComponents/Profile/AuthorFlair/AuthorFlair";
import { styleHelper } from "../../../styles/styles";
import { CardModal } from "../../ApplicationComponents/CardModal/CardModal";
import { SkeletonLoaderPreset } from "../../ApplicationComponents/SkeletonLoaderPreset/SkeletonLoaderPreset";

export const ProfileModal = () => {
  const { data } = useSelector(selectModalState).profileModal;

  const _isDarkMode = useSelector(selectIsDarkMode);

  // TODO: Fetch user Profile Details

  return (
    <CardModal modalId={"profileModal"}>
      {data?.username && (
        <Stack gap={"10px"}>
          <Stack alignItems="center">
            <AuthorAvatar
              size={"75px"}
              username={data?.username}
              showOnlyProfileImage
              imgProps={{
                style: {
                  filter: "drop-shadow(2px 2px 2px rgba(0,0,0, 0.3))",
                },
              }}
            />
            <Stack direction="row">
              <Typography fontWeight="bold">
                <span
                  style={{
                    opacity: 0.35,
                    fontSize: "0.8rem",
                    fontWeight: 400,
                  }}
                >
                  u/
                </span>
                {data?.username}
              </Typography>
            </Stack>
            <Divider sx={{ paddingTop: "10px" }} flexItem />
          </Stack>
          {!!data?.subreddit && (
            <Stack direction="row" pt={"5px"}>
              <SubIcon sub={data?.subreddit} />
              <Typography
                fontSize={"large"}
                sx={{
                  fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    opacity: 0.5,
                    fontSize: "0.8rem",
                    fontWeight: 400,
                  }}
                >
                  r/
                </span>{" "}
                {data?.subreddit}
              </Typography>
            </Stack>
          )}
          {!!data?.flair && (
            <AuthorFlair
              flair={data?.flair}
              imageFlairSize={"50px"}
              ImageFlairStyle={{
                borderRadius: "10px",
              }}
            />
          )}
          <SkeletonLoaderPreset variant={"paragraph"} />
        </Stack>
      )}
    </CardModal>
  );
};
