import { Box, Typography, Modal, Stack, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsDarkMode,
  selectIsDesktopMode,
  selectModalState,
} from "../../../redux/selectors/appState.selectors";
import { updateModal } from "../../../redux/slices/application.slice";
import { THEME_MODES } from "../../../config";
import { RawJSONView } from "./RawJSONView";
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  overflowY: "scroll",
  overflowH: "hidden",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "5vw",
  boxShadow: 24,
  p: 4,
  backgroundColor: "rgb(255 255 255 / 88%)",
  backdropFilter: "blur(10px) saturate(1.5)",
  WebkitBackdropFilter: "blur(10px) saturate(1.5)",
};

export const JSONModal = () => {
  const dispatch = useDispatch();
  const { isOpen, data } = useSelector(selectModalState).jsonModal;

  const isDesktopMode = useSelector(selectIsDesktopMode);
  const isDarkMode = useSelector(selectIsDarkMode);

  const handleClose = () =>
    dispatch(
      updateModal({
        modalId: "jsonModal",
        isOpen: false,
      })
    );
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...modalStyle,
          ...(isDesktopMode
            ? {}
            : { width: "100vw", height: "100vh", borderRadius: "0px" }),
          ...(isDarkMode ? { backgroundColor: "rgb(0 0 0 / 88%)" } : {}),
        }}
      >
        <Stack
          sx={{ position: "sticky", top: 0, left: 0, zIndex: 1 }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            fontWeight="bold"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            JSON Payload
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box sx={{ overflowX: "hidden", overflowY: "scroll" }} pb={"80px"}>
          <RawJSONView jsonText={data} />
        </Box>
      </Box>
    </Modal>
  );
};
