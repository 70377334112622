import { Divider, Skeleton } from "@mui/material";
import "./PostLoader.css";

export const PostLoader = ({}) => {
  const flex = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "0px 10px",
    boxSizing: "border-box",
  };
  const skeletonStyles = {
    transform: "none",
  };
  return (
    <div className="post-loader">
      <Skeleton sx={skeletonStyles} />
      <div style={{ opacity: 0.75, ...flex }}>
        <Skeleton
          sx={{
            width: "25px",
            height: "25px",
            flexShrink: 0,
            ...skeletonStyles,
          }}
          variant="circular"
        />
        <Skeleton sx={{ width: "100%", height: "20px", ...skeletonStyles }} />
      </div>
      <div style={{ opacity: 0.5 }}>
        <Skeleton
          sx={{
            height: "40px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "10px",
            ...skeletonStyles,
          }}
        />
      </div>
      <Divider />
    </div>
  );
};
