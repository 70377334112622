import { Stack, Typography } from "@mui/material";
import {
  formatNumberPrefixed,
  getScoreGradientColors,
} from "../../../../helpers/functions";
import { GradientText } from "../../../ApplicationComponents/GradientText/GradientText";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../../redux/selectors/appState.selectors";
import { ArrowUpward } from "@mui/icons-material";

export const CommentScore = ({ comment }) => {
  const { ups } = comment;

  const n = formatNumberPrefixed(ups);
  const isNegative = ups < 0;

  const { color1, color2 } = getScoreGradientColors(comment.ups);
  const isDarkMode = useSelector(selectIsDarkMode);

  const getIcon = () => {
    if (ups === 0 || ups === 1) return "";
    if (!isNegative)
      return (
        <ArrowUpward
          sx={{ opacity: 0.75, color: isDarkMode ? color1.dark : color1.light }}
          fontSize={"9px"}
        />
      );

    return (
      <ArrowUpward
        sx={{
          opacity: 0.75,
          color: isDarkMode ? color1.dark : color1.light,
          transform: "scaleY(-1)",
        }}
        fontSize={"9px"}
      />
    );
  };
  return (
    <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
      {getIcon()}
      <GradientText
        color1={isDarkMode ? color1.dark : color1.light}
        color2={isDarkMode ? color2.dark : color2.light}
        TypographyProps={{
          fontSize: "13px",
          fontWeight: "bold",
        }}
      >
        {ups === 0 ? "--" : n}
      </GradientText>
      {/*<Typography fontWeight={"bold"}></Typography>*/}
    </Stack>
  );
};
