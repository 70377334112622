import { Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { selectModalState } from "../../../redux/selectors/appState.selectors";
import { CardModal } from "../../ApplicationComponents/CardModal/CardModal";
import { SkeletonLoaderPreset } from "../../ApplicationComponents/SkeletonLoaderPreset/SkeletonLoaderPreset";
import { useEffect } from "react";
import { OpenGraphCard } from "../../ApplicationComponents/OpenGraphCard/OpenGraphCard";

export const WebsiteModal = () => {
  const { data } = useSelector(selectModalState).websiteModal;

  useEffect(() => {
    if (!data) return;

    console.log("Got domain: ", data);
  }, []);
  if (!data) return null;
  return (
    <CardModal modalId={"websiteModal"}>
      <Stack gap={"10px"} alignItems={"center"}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: "50px",
            height: "50px",
            "& .modal-domain-icon": {
              width: "100%",
              borderRadius: "10px",
              border: "1px solid rgba(0,0,0,0.15)",
              boxShadow: "0px 0px 5px rgba(0,0,0,0.15)",
            },
          }}
        >
          <img className={"modal-domain-icon"} src={data.favIcon} />
        </Stack>
        <Typography fontWeight={"bold"} align={"center"}>
          {data.domain}
        </Typography>
        <Stack sx={{ width: "100%" }}>
          <OpenGraphCard url={data.domain} />
        </Stack>
      </Stack>
    </CardModal>
  );
};
