import { CloseDrawerButton } from "../CloseDrawerButton/CloseDrawerButton";
import { SwipeableDrawer as Drawer } from "@mui/material";
import { SubredditView } from "../../Views/SubredditView/SubredditView";
import { drawerStyles } from "../../../styles/styles";
import { selectors } from "../../../redux";
import { useSelector } from "react-redux";

export const SubDrawer = ({ onClose, onClickThread }) => {
  const activeSub = useSelector(selectors.selectActiveSub);
  const activeThread = useSelector(selectors.selectActiveThread);
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);

  const overrides = {
    width: "calc(100vw - 1100px)",
    left: "550px",
    right: "auto",
    "& .MuiBackdrop-root": {
      width: "calc(100vw - 1100px)",
      left: "550px",
      right: "auto",
    },
  };

  const enableOverrides = isDesktopMode && !!activeThread;
  const modalProps = enableOverrides ? { sx: overrides } : {};
  const paperProps = enableOverrides ? { sx: { maxWidth: "550px" } } : {};

  /* 
  
  */
  return (
    <Drawer
      sx={drawerStyles}
      open={!!activeSub}
      ModalProps={modalProps}
      PaperProps={paperProps}
      onClose={onClose}
      anchor="left"
      onOpen={() => {}}
    >
      {isDesktopMode && <CloseDrawerButton onClick={onClose} />}
      <div style={{ width: "100%", height: "100%" }}>
        <SubredditView
          sub={activeSub}
          activeThread={activeThread}
          onClickThread={onClickThread}
        />
      </div>
    </Drawer>
  );
};
