import { IconButton, Stack } from "@mui/material";
import { AuthorAvatar } from "../../../RedditComponents/Profile/AuthorAvatar/AuthorAvatar";
import { Link, SettingsEthernet } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateModal } from "../../../../redux/slices/application.slice";
import { getAuthorFlairProps } from "../../../../helpers/functions";

export const ThreadActions = ({ thread }) => {
  const { url } = thread;
  const authorFlairProps = getAuthorFlairProps(thread);

  const dispatch = useDispatch();
  const handleOpenJSONModal = () => {
    dispatch(
      updateModal({
        modalId: "jsonModal",
        isOpen: true,
        data: thread,
      })
    );
  };

  const handleClickProfile = () => {
    dispatch(
      updateModal({
        modalId: "profileModal",
        data: {
          username: thread.author,
          flair: getAuthorFlairProps(thread),
          subreddit: thread?.subreddit,
        },
        isOpen: true,
      })
    );
  };

  return (
    <Stack mt={"12px"} gap={"5px"} direction={"row"} width={"fit-content"}>
      <AuthorAvatar
        onClick={handleClickProfile}
        username={thread.author}
        isOP={true}
        flair={authorFlairProps}
        AuthorFlairProps={{
          truncate: true,
          truncateLimit: 4,
        }}
      />
      <IconButton onClick={() => window.open(url, "_blank")}>
        <Link />
      </IconButton>
      <IconButton onClick={handleOpenJSONModal}>
        <SettingsEthernet />
      </IconButton>
    </Stack>
  );
};
