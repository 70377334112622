import { Avatar, ButtonBase, Chip, Typography } from "@mui/material";
import { styleHelper } from "../../../styles/styles";
import { useDispatch } from "react-redux";
import { updateModal } from "../../../redux/slices/application.slice";

export const DomainChip = ({
  favIcon,
  domain,
  sx = {},
  onClick = () => {},
}) => {
  const dispatch = useDispatch();
  const maxLen = 18;
  const text =
    domain.length > maxLen ? domain.slice(0, maxLen) + "..." : domain;
  const handleClick = () => {
    onClick && onClick();

    // TODO: Open Domain modal
    dispatch(
      updateModal({
        isOpen: true,
        modalId: "websiteModal",
        data: {
          domain,
          favIcon,
        },
      })
    );
  };
  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        borderRadius: "20px",
      }}
    >
      <Chip
        sx={(theme) => ({
          ...styleHelper.blurBackgroundStyles(),
          border: `1px solid ${theme.palette.secondary.main}`,
          ...sx,
        })}
        size={"small"}
        label={
          <Typography
            ml={"2px"}
            fontSize={"small"}
            sx={{
              userSelect: "none",
              fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
              color: "#d0d0d0",
              textShadow: "0px 1px 5px rgba(0,0,0,0.5)",
            }}
            noWrap
            fontWeight="bold"
          >
            {text}
          </Typography>
        }
        avatar={<Avatar src={favIcon} />}
      />
    </ButtonBase>
  );
};
