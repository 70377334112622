import "./SubList.css";

import {
  shuffleArr,
  sortStrCaseIgnore,
  stringToColour,
} from "../../../../helpers/functions";

import { ALLOWED_SUBS } from "../../../../config";
import { SearchOff } from "@mui/icons-material";
import { SubCard } from "../SubCard/SubCard";
import { selectors } from "../../../../redux";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";

export const SubList = ({ onClickSub, filterTerm = "", customList = [] }) => {
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);
  const [virtualIndex, setVirtualIndex] = useState(10);

  const filteredSubs = [...ALLOWED_SUBS]
    .sort(sortStrCaseIgnore)
    .filter((sub) => sub.toLocaleLowerCase().includes(filterTerm))
    .filter((sub) => {
      if (customList.length === 0) {
        return true;
      }
      const subs = customList.map((s) => s.toLowerCase());
      return subs.includes(sub.toLowerCase());
    });

  const mobileStyles = {
    paddingBottom: "70px",
  };

  const handleLoadMore = () => {
    if (virtualIndex >= filteredSubs.length) return;

    setVirtualIndex((prev) => {
      if (prev + 10 > filteredSubs.length) {
        console.log("vindex maxed: ", filteredSubs.length - prev);
        return filteredSubs.length;
      } else {
        console.log("vindex: ", prev + 10);
        return prev + 10;
      }
    });
  };
  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom =
        scrollTop + clientHeight >= scrollHeight - 0.12 * scrollHeight;

      if (isNearBottom) {
        handleLoadMore();
      }
    }
  };

  useEffect(() => {
    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  return (
    <Box
      component={"ul"}
      ref={listInnerRef}
      className="sub-list"
      style={isDesktopMode ? {} : mobileStyles}
    >
      {filteredSubs.slice(0, virtualIndex).map((sub) => (
        <SubCard
          style={{
            "&:hover": { cursor: "pointer" },
            "&:hover .sub-background": {
              transform: "scale(1.15)",
              filter: "saturate(1.5)",
              translate: "10px -10px",
            },
            "&:hover .shadow-overlay": {
              height: "50%",
            },
          }}
          key={sub}
          sub={sub}
          onClick={() => onClickSub(sub)}
        />
      ))}
      {filteredSubs.length === 0 && <NullState />}
    </Box>
  );
};

const NullState = () => {
  const style = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "7px",
    padding: "25px",
  };
  return (
    <Paper sx={style}>
      <SearchOff sx={{ fontSize: "60px" }} />
      <Typography>No subreddits found.</Typography>
    </Paper>
  );
};
