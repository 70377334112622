import { Stack, Typography } from "@mui/material";
import { Block } from "@mui/icons-material";

export const BlockedSubredditBadge = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        padding: "50px",
      }}
    >
      <Block sx={{ color: "#fa5252", fontSize: "50px" }} />
      <Typography>This subreddit is blocked</Typography>
      <Typography sx={{ opacity: 0.5 }} variant={"caption"}>
        Include this sub in your config to enable
      </Typography>
    </Stack>
  );
};
