import { Box, CircularProgress, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { VideoLoader } from "../../../Loaders/VideoLoader/VideoLoader";
import { getThreadGallery } from "../../../../helpers/functions";

export const ThreadMedia = ({ post = {} }) => {
  const [mediaPlaybackUrl, setMediaPlaybackUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mediaEmbedHTML, setMediaEmbedHTML] = useState("");
  useEffect(() => {
    //setMediaPlaybackUrl("");
    if (post?.media) {
      setIsLoading(true);
      if (
        post.media["reddit_video"] &&
        post.media["reddit_video"]["fallback_url"]
      ) {
        console.log(
          "video detected!!",
          post.media["reddit_video"]["fallback_url"]
        );
        setTimeout(() => {
          setIsLoading(false);
          setMediaPlaybackUrl(post.media["reddit_video"]["fallback_url"]);
        }, 1500);
      } else if (post.media?.oembed) {
        setMediaEmbedHTML(post.media?.oembed?.html);
        setIsLoading(false);
      }
    }
  }, [post]);

  if (!Object.keys(post)) return null;

  return (
    <Box>
      {mediaEmbedHTML && (
        <Box
          sx={{
            "& iframe": {
              width: "100%!important",
            },
          }}
          dangerouslySetInnerHTML={{ __html: mediaEmbedHTML }}
        ></Box>
      )}
      {isLoading && <VideoLoader />}
      {mediaPlaybackUrl && !isLoading && (
        <div className="thread-media-container">
          <video
            style={{ borderRadius: "15px" }}
            className="thread-media-video-player"
            controls={true}
          >
            <source src={mediaPlaybackUrl} type="video/mp4"></source>
          </video>
        </div>
      )}
    </Box>
  );
};
