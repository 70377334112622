import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectModalState } from "../../../redux/selectors/appState.selectors";
import { CardModal } from "../../ApplicationComponents/CardModal/CardModal";
import { subredditObject } from "../../../data/subreddits";
import { SubIcon } from "../../RedditComponents/Subreddit/SubIcon/SubIcon";
import { SubBackground } from "../../RedditComponents/Subreddit/SubBackground/SubBackground";
import {
  checkIfContentIsNSFW,
  stringToColour,
} from "../../../helpers/functions";
import * as actions from "../../../redux/slices/application.slice";
import { updateModal } from "../../../redux/slices/application.slice";
import { OpenInNew } from "@mui/icons-material";
import {
  styleHelper,
  subredditDescriptionStyles,
} from "../../../styles/styles";
import { useEffect, useState } from "react";
import { getSubredditAboutJSON } from "../../../api/base";
import { ErrorMessageDisplay } from "../../ApplicationComponents/ErrorMessageDisplay/ErrorMessageDisplay";
import { BlockedSubredditBadge } from "../../ApplicationComponents/NSFWComponents/BlockedSubredditBadge";
import {
  decodeHTMLEntities,
  parseInjectHTMLBeforeRender,
} from "../../../helpers/HTML";
import { SubSimilarSubreddits } from "../../RedditComponents/Subreddit/SubSimilarSubreddits/SubSimilarSubreddits";

export const SubredditModal = () => {
  const { data, isOpen } = useSelector(selectModalState).subredditModal;
  const dispatch = useDispatch();

  const sub = data?.sub;

  const [subreddit, setSubreddit] = useState(
    subredditObject[sub?.toLowerCase()]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isNSFW, setIsNSFW] = useState(false);

  const handleVisitSubreddit = () => {
    dispatch(
      updateModal({
        modalId: "subredditModal",
        isOpen: false,
      })
    );
    dispatch(actions.setActiveSub(sub));
  };
  const fetchSubreddit = () => {
    const localSub = subredditObject[sub?.toLowerCase()];
    if (localSub) {
      setSubreddit(localSub);
      return;
    }
    // Fetch external subreddit details
    setIsLoading(true);
    const newSubreddit = {};
    newSubreddit.name = sub;
    newSubreddit.tags = [];

    getSubredditAboutJSON(sub)
      .then((data) => {
        console.log("Got data: ", data);
        if (data) {
          newSubreddit.slogan = data["public_description"];
          newSubreddit.description =
            decodeHTMLEntities(data["description_html"]) ||
            data["public_description"];
          newSubreddit.icon = data["icon_img"];
          newSubreddit.banner = data["banner_img"];
        } else {
          newSubreddit.description = `No description for sub: r/${sub}`;
        }
        setSubreddit(newSubreddit);
        setIsLoading(false);
        if (checkIfContentIsNSFW(sub, data["over18"])) {
          console.log("sub is over 18 and NOT in list");
          setIsNSFW(true);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        console.log("Error fetching subreddit details: ", err);
      });
  };

  useEffect(() => {
    setError(false);
  }, [sub]);

  useEffect(() => {
    if (isOpen && !isNSFW) {
      fetchSubreddit();
    } else {
      setIsLoading(false);
      setError(false);
    }
  }, [sub]);

  if (!data) return null;

  return (
    <CardModal
      sx={{
        padding: 0,
      }}
      modalId={"subredditModal"}
      sheet
    >
      {isNSFW && <BlockedSubredditBadge />}
      {error && (
        <ErrorMessageDisplay
          message={"Unable to get subreddit details"}
          onRetry={fetchSubreddit}
        />
      )}
      {isLoading && !error && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress size={65} />
        </Box>
      )}
      {!isLoading && !error && !isNSFW && (
        <Stack alignItems={"center"}>
          <Stack
            sx={{
              position: "relative",
              width: "100%",
              display: "grid",
              placeItems: "center",
              height: "200px",
            }}
          >
            <Stack
              justifyContent="flex-start"
              sx={{ width: "100%" }}
              direction={"row"}
            >
              <Stack
                sx={{ width: "100%", padding: "20px" }}
                alignItems={"flex-start"}
              >
                <SubIcon
                  sx={{
                    width: "100px",
                    height: "100px",
                    zIndex: 3,
                    padding: 0,
                  }}
                  ImgProps={{
                    style: {
                      width: "100%",
                      borderRadius: "12px",
                    },
                  }}
                  sub={sub}
                  overrideIcon={subreddit?.icon}
                />
                <Typography
                  sx={{
                    zIndex: 3,
                    position: "relative",
                    color: "white",
                  }}
                  fontSize={"x-large"}
                  fontWeight={"bold"}
                  align={"center"}
                >
                  {subreddit?.name}
                </Typography>
              </Stack>
              <Stack
                sx={{ width: "50%", height: "100%", p: 1, marginTop: "auto" }}
              >
                <Button
                  sx={{
                    zIndex: 3,
                    backgroundColor: "rgb(246 251 255 / 15%)",
                    ...styleHelper.blurBackgroundStyles("10px"),
                  }}
                  variant={"contained"}
                  onClick={handleVisitSubreddit}
                  endIcon={<OpenInNew />}
                >
                  Visit
                </Button>
              </Stack>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                overflow: "hidden",
                height: "100%",
                width: "100%",
              }}
            >
              <SubBackground
                style={{}}
                sub={sub}
                useFallback
                variant={2}
                withShadow
                overrideImg={subreddit?.banner}
              />
            </Box>
          </Stack>
          <Stack
            sx={{
              p: 4,
              gap: "10px",
              maxHeight: "500px",
              overflow: "auto",
              width: "100%",
              overflowX: "hidden",
              ...subredditDescriptionStyles(),
            }}
          >
            <Box
              className={"comment-body-injected-html"}
              dangerouslySetInnerHTML={{
                __html: parseInjectHTMLBeforeRender(subreddit?.description),
              }}
            ></Box>
            <SubSimilarSubreddits sub={sub} />
            <Divider />
            <Stack mt={"20px"} gap="10px" width={"100%"}>
              <RenderTagList
                tags={subreddit?.tags || []}
                sub={subreddit?.name}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </CardModal>
  );
};

const RenderTagList = ({ tags, sub }) => {
  if (tags.length === 0) {
    return (
      <Typography
        variant={"caption"}
        sx={{
          opacity: 0.5,
        }}
        align={"center"}
      >
        There are no tags for this subreddit
      </Typography>
    );
  }
  return (
    <>
      <Typography variant={"caption"}>Themes and ideas:</Typography>{" "}
      <Stack
        sx={{
          flexWrap: "wrap",
          gap: "10px",
        }}
        direction={"row"}
      >
        {tags.map((tag, index) => (
          <Tag key={index} tag={tag} color={stringToColour(sub, "45")} />
        ))}
      </Stack>{" "}
    </>
  );
};
const Tag = ({ tag, color }) => {
  return (
    <Chip
      sx={{ backgroundColor: color ? color : stringToColour(tag, "45") }}
      label={tag}
    />
  );
};
