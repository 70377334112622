import { Button, Collapse, Text } from "@mantine/core";
import { CopyAll, Link } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { THEME_MODES } from "../../../config";
import {
  convertHexToRgbaTranparency,
  getAuthorFlairProps,
  isCommentHidden,
  replaceBodyURLs,
  stringToColour,
} from "../../../helpers/functions";
import { actions, selectors } from "../../../redux";
import "./Comment.css";
import { AuthorAvatar } from "../Profile/AuthorAvatar/AuthorAvatar";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { CommentMenu } from "./components/CommentMenu";
import { CommentHeader } from "./components/CommentHeader";
import { CommentChildren } from "./components/CommentChildren";
import { CommentMediaGIF } from "./components/CommentMediaGIF";
import { getCommentData } from "./commentHelper";
import { CommentBody } from "./components/CommentBody";

export const Comment = ({ comment, opUsername }) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector(selectors.selectTheme) === THEME_MODES.dark;
  const hiddenComments = useSelector(selectors.selectHiddenComments);
  const [isVisible, setIsVisible] = useState(true);

  // Get Comment Props
  const commentData = getCommentData(comment);

  useEffect(() => {
    //  console.log('got comment:', comment);
    if (hiddenComments.length) {
      if (isCommentHidden(hiddenComments, commentData.id.commentIdWithParent)) {
        setIsVisible(false);
      }
    }
  }, [hiddenComments]);

  const handleCollapseComment = () => {
    setIsVisible((prevState) => !prevState);

    dispatch(
      actions.toggleCommentVisibility(commentData.id.commentIdWithParent)
    );
  };

  const handleClickProfile = () => {
    dispatch(
      actions.updateModal({
        modalId: "profileModal",
        data: {
          username: commentData.author.username,
          flair: commentData.author.flair,
          subreddit: comment?.subreddit,
        },
        isOpen: true,
      })
    );
  };

  const handleScrollCommentIntoView = () => {
    try {
      const el = document.querySelector(`[data-comment="${comment.id}"]`);
      el.scrollIntoView({ behavior: "smooth" });
    } catch (e) {
      return;
    }
  };

  return (
    <div
      style={{
        backgroundColor: isDarkMode ? undefined : "white",
        borderRadius: "5px",
      }}
      className={`${commentData.bool.isAutoMod ? "comment-is-auto-mod" : ""}`}
    >
      <Box
        className={`comment ${
          commentData.bool.isAutoMod ? "comment-is-auto-mod" : ""
        }`}
        sx={{
          backgroundColor: isDarkMode
            ? commentData.colors.secondary.dark
            : commentData.colors.secondary.light,
          borderColor: commentData.colors.primary.light + "90",

          ...(commentData.bool.isFirstComment || commentData.bool.isAutoMod
            ? {
                border: `1px solid ${commentData.colors.primary.light}`,
                borderRadius: "8px!important",
              }
            : {
                border: `1px solid ${commentData.colors.primary.light}`,
                borderRadius: "5px 0px 0px 5px",
                borderBottom: "0px",
                borderRight: "0px",
              }),
        }}
      >
        <CommentHeader
          comment={comment}
          isOP={commentData.bool.isOP}
          onCollapse={handleCollapseComment}
          isVisible={isVisible}
          isAutoMod={commentData.bool.isAutoMod}
          onClickProfile={handleClickProfile}
          authorFlairProps={commentData.author.flair}
        />
        <Collapse in={isVisible}>
          <CommentBody
            comment={comment}
            isVisible={isVisible}
            onClickCollapseBar={() => {
              handleCollapseComment();
              handleScrollCommentIntoView();
            }}
            onCollapseComment={handleCollapseComment}
          />
          <CommentChildren
            isVisible={isVisible}
            replies={commentData.replies}
            opUsername={opUsername}
          />
        </Collapse>
      </Box>
    </div>
  );
};
