import { subredditObject, SUBREDDITS } from "../data/subreddits";
import { THEME_MODES } from "../config";
import { getSubredditMetaData } from "../api/base";
import {
  getSubredditFromIndexedDB,
  saveSubredditToIndexedDB,
} from "./indexedDB";

export function convertHexToRgbaTranparency(hexColor, transparency) {
  // Remove the "#" character from the beginning of the hexColor string
  hexColor = hexColor.replace("#", "");

  // Convert the hex color value to RGB values
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);

  // Create the rgba color string with the transparency level included
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${transparency})`;

  return rgbaColor;
}

export const stringToColour = function (str, opacity = false) {
  if (typeof str !== "string") return "#000000";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return opacity ? colour + opacity : colour;
};

export const scrollToTop = () => {
  // Tip: for slower motion of the scrolling, increase the hardcoded number 8.
  // The bigger the number - the smoother/slower the scrolling.
  const c = document.documentElement.scrollTop || document.body.scrollTop;

  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 13);
  }
};

export const timeStringClean = (date) => {
  let [time, AMPM] = date.toLocaleTimeString().split(" ");

  time = time.slice(0, -3);
  return `${time} ${AMPM}`;
};

export const dayOfWeek = (date) => {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][date.getDay()];
};

export const sortStrCaseIgnore = (a, b) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const SORT_METHOD_KEYS = {
  VOTES_ASCENDING: "VOTES_ASCENDING",
  DEFAULT: "DEFAULT",
};
export const SUB_SORT_METHODS = {
  [SORT_METHOD_KEYS["VOTES_ASCENDING"]]: (a, b) => b.ups - a.ups,
  [SORT_METHOD_KEYS.DEFAULT]: function (a, b) {
    return 1;
  },
};

export function shuffleArr(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const parseCustomThread = (rawThread) => {
  try {
    const root = rawThread[0]["children"][0]["data"];

    return root;
  } catch (err) {
    return false;
  }
};

export const getNewPostHistoryArray = (thread, postHistory) => {
  const newArr = [...postHistory].filter((item) => item.id != thread.id);
  newArr.push(thread);
  return newArr;
};

export const createPostHistoryItem = (thread) => {
  const item = {
    id: thread.id,
    title: thread.title,
    sub: thread.subreddit,
    permalink: thread.permalink,
    dateVisited: new Date().toISOString(),
    post: JSON.stringify(thread),
  };
  return item;
};

export const isCommentHidden = (comments, id) => comments.includes(id);
export const isPostVisited = (postHistory, id) => {
  const posts = [...postHistory].map((post) => post.id);
  return posts.includes(id);
};

export const getSubJSONData = (sub) => {
  const defaultObj = {
    name: sub,
    description: "",
    slogan: "",
    tags: [],
  };

  if (!sub) return defaultObj;

  const index = SUBREDDITS.findIndex(
    (subreddit) => subreddit.name.toLowerCase() === sub.toLowerCase()
  );

  if (index === -1) {
    return defaultObj;
  } else {
    return SUBREDDITS[index];
  }
};

export const getFavoritedSubs = () => {
  let favs = localStorage.getItem("redcloud-favorite-subs");
  if (favs) {
    favs = JSON.parse(favs);
    if (!favs?.length) {
      favs = [];
    }
  } else {
    favs = [];
  }

  return favs;
};

export const toggleFavoriteSub = (sub) => {
  let favs = getFavoritedSubs();

  if (favs.includes(sub)) {
    favs = favs.filter((subName) => subName != sub);
  } else {
    favs.push(sub);
  }

  localStorage.setItem("redcloud-favorite-subs", JSON.stringify(favs));
};

export const isSubFavorited = (sub) => {
  const favs = getFavoritedSubs();
  if (!favs.length) {
    return false;
  }

  return favs.includes(sub);
};

export const getUserThemeFromLS = () => {
  let theme = localStorage.getItem("redcloud-user-theme");

  if (!theme || ![THEME_MODES.dark, THEME_MODES.light].includes(theme))
    return THEME_MODES.light;

  return theme;
};

export const saveUserThemeToLS = (theme) => {
  localStorage.setItem("redcloud-user-theme", theme);
};

export function replaceBodyURLs(str) {
  // Regular expression to match URLs with optional link text and brackets
  const urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

  // Replace URLs with <a> tags
  const replacedStr = str.replace(
    urlRegex,
    '<a href="$2" target="_blank">🔗 <span class="link-text">$1</span></a>'
  );

  return replacedStr;
}

export function getAuthorFlairProps(data) {
  const result = {
    author_flair_template_id: null,
    author_flair_css_class: null,
    author_flair_richtext: [],
    author_flair_type: null,
    author_flair_text: null,
    author_flair_text_color: null,
    author_patreon_flair: null,
  };
  for (const prop of Object.keys({ ...result })) {
    let value = data[prop];
    if (value != null && value != undefined) {
      result[prop] = value;
    }
  }

  //console.log("got flair object: ", result);
  return result;
}

export const getColorForUpvotes = (upvoteAmount) => {
  if (upvoteAmount > 1000) {
    return "gold";
  } else if (upvoteAmount > 700) {
    return "orange";
  } else if (upvoteAmount > 500) {
    return "green";
  } else if (upvoteAmount > 100) {
    return "#00b4d8";
  } else if (upvoteAmount > 50) {
    return "#2ec4b6";
  } else {
    return "gray";
  }
};
//https://www.reddit.com/r/xbox360/comments/14wa6p1/which_dashboard_was_your_favorite/
export const getThreadGallery = (thread) => {
  const { media_metadata, gallery_data } = thread;
  const result = {
    hasGallery: false,
    images: [],
  };

  if (!media_metadata || !gallery_data) {
    return result;
  } else {
    try {
      gallery_data.items.forEach((image) => {
        const metadata = media_metadata[image.media_id];

        const imageObj = {
          id: image.media_id,
          caption: image?.caption ?? "",
          key: image.id,
          ...parseMetadata(metadata),
        };

        result.images.push(imageObj);
      });

      result.hasGallery = true;
      return result;
    } catch (err) {
      console.log("Error parsing thread gallery: ", err);
      return result;
    }
  }
};

const parseMetadata = (data = {}) => {
  const previewImage = parseImagePreviewObj(data.s);
  const result = {
    ...previewImage,
    type: data.e ?? "null",
    status: data.status ?? "null",
    format: data.m ?? "null",
    imageSizes: data?.p?.map((item) => parseImagePreviewObj(item)) ?? [],
  };

  return result;
};

const parseImagePreviewObj = (imagePreview) => {
  const { x, y, u } = imagePreview;

  return {
    url: u,
    width: x,
    height: y,
  };
};

export function formatNumberPrefixed(n) {
  if (n < 1000) return n;
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixIndex = Math.floor(Math.log10(n) / 3);
  const shortNumber = (n / Math.pow(10, suffixIndex * 3)).toFixed(1);

  return shortNumber + suffixes[suffixIndex];
}
export const getScoreGradientColors = (score) => {
  const createColor = (light, dark) => {
    return {
      light,
      dark,
    };
  };

  const result = {
    color1: createColor("rgba(0,0,0,0.75)", "rgba(255,255,255,0.25)"),
    color2: createColor("rgba(0,0,0,0.72)", "rgba(206,206,206,0.63)"),
  };
  if (score < 0) {
    result.color1 = createColor("#ff0000", "#ff2e2e");
    result.color2 = createColor("#af0032", "#c4006a");
  }
  if (score < 100) {
    return result;
  }
  if (score > 100 && score < 399) {
    result.color1 = createColor("#4ec9b0", "#4aeac9");
    result.color2 = createColor("#19967d", "#04e3b5");
  }
  if (score > 399 && score < 799) {
    result.color1 = createColor("#0f87ff", "#0f87ff");
    result.color2 = createColor("#004693", "#c3e5ff");
  }
  if (score > 799 && score < 999) {
    result.color1 = createColor("#d06b07", "#f85c40");
    result.color2 = createColor("#D4781C", "#ff7e00");
  }
  if (score > 1000) {
    result.color1 = createColor("#fcd207", "#fcdf59");
    result.color2 = createColor("#c2a206", "#c5a403");
  }

  return result;
};

export const openURLInNewTab = (url, options = "nofollow, noreferrer") => {
  let attributes = "_blank";

  if (options && options.includes("nofollow")) {
    attributes += " nofollow";
  }

  if (options && options.includes("noreferrer")) {
    attributes += " noreferrer";
  }

  window.open(url, attributes);
};

export const checkIfContentIsNSFW = (sub, over18) => {
  if (subredditObject[sub?.toLowerCase()]) return false;
  if (over18) return true;

  return false;
};

export function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const saveSubredditData = async (data) => {
  if (!data) return;

  try {
    const {
      details: { name },
    } = data;

    window["subreddits"][name.toLowerCase()] = data;

    await saveSubredditToIndexedDB(data);
  } catch (err) {
    return console.error("Unable to save subreddit due to error");
  }
};

export const getSubredditData = async (sub) => {
  if (!sub) return;

  const subData = window["subreddits"][sub.toLowerCase()];

  if (subData) return subData;

  try {
    const data = await getSubredditFromIndexedDB(sub);
    if (data) {
      console.log("Sub found inside indexedDB!: ", data);
      await saveSubredditData(data);
      return data;
    } else {
      console.log("Sub not found in indexedDB!: ", sub);
      console.log("fetching sub!");
      const data = await fetchSubredditData(sub);
      return data;
    }
  } catch (err) {
    console.error("Error getting datbase subreddit: ", err);
  }

  return false;
};

export const fetchSubredditData = async (sub) => {
  const subData = window["subreddits"][sub.toLowerCase()];

  if (subData) return subData;

  // Check indexedDB for the sub
  try {
    const data = await getSubredditFromIndexedDB(sub);
    if (data) {
      console.log("Sub inside indexedDB!: ", data);
      await saveSubredditData(data);
      return data;
    }
  } catch (err) {
    console.error("Error getting database subreddit: ", err);
  }

  try {
    // Else, we
    // make a network API request to get the data
    const data = await getSubredditMetaData(sub.toLowerCase());
    if (!data) {
      console.error("Error indexing subreddit: ", sub);
    }
    await saveSubredditData(data);
  } catch (err) {
    throw new Error("Unable to get subreddit data: " + sub);
  }
};
