import { useEffect, useState } from "react";
import { nullSubBackground } from "../../../../assets";
import { STATIC_ASSET_URL } from "../../../../config";
import "./SubBackground.css";
import { fetchSubredditData } from "../../../../helpers/functions";

const getURL = (url, variant, sub) => {
  const variantURL = `${STATIC_ASSET_URL}/reddit/backgrounds/${sub.toLowerCase()}_${variant}x.jpeg`;

  // Check if this is a custom background
  if (url.startsWith(STATIC_ASSET_URL)) {
    return variantURL;
  } else {
    return url;
  }
};
export const SubBackground = ({
  sub,
  overrideImg = "",
  withShadow,
  style = {},
  className = "",
  useFallback = false,
  variant = 1,
  onError = () => {},
}) => {
  const [url, setUrl] = useState(nullSubBackground);

  useEffect(() => {
    fetchSubredditData(sub)
      .then((data) => {
        console.log("data: ", data.details.artwork.background);

        const background = getURL(
          data.details.artwork.background,
          variant,
          sub
        );
        setUrl(background);
      })
      .catch((err) => {
        console.log(`SubIcon Error: sub r/${sub}`);
        console.log(err);
      });
  }, [sub]);

  return (
    <>
      {withShadow && <div className="shadow-overlay"></div>}
      <img
        style={style}
        onError={() => setUrl(nullSubBackground)}
        className={`sub-background ${className}`}
        src={url}
      />
    </>
  );
};
export const _SubBackground = ({
  sub,
  style = {},
  className = "",
  withShadow = false,
  useFallback = false,
  variant = 1,
  onError = () => {},
  overrideImg = "",
}) => {
  const getUrl = () =>
    overrideImg
      ? overrideImg
      : `${STATIC_ASSET_URL}/reddit/backgrounds/${sub.toLowerCase()}_${variant}x.jpeg`;
  const [imgUrl, setImgUrl] = useState(getUrl());
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
    if (useFallback) {
      setImgUrl(nullSubBackground);
      return;
    }
    onError();
  };

  useEffect(() => {
    setIsError(false);
    setImgUrl(getUrl());
  }, [sub]);

  if (isError && !useFallback) return null;

  return (
    <>
      {withShadow && <div className="shadow-overlay"></div>}
      <img
        style={style}
        onError={handleError}
        className={`sub-background ${className}`}
        src={imgUrl}
      />
    </>
  );
};
