import { Box, Skeleton } from "@mui/material";

const ParagraphLoader = () => (
  <Box sx={{ overflowX: "hidden", overflowY: "scroll" }}>
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
  </Box>
);

const TextLoader = () => (
  <Box sx={{ overflowX: "hidden", overflowY: "scroll" }}>
    <Skeleton animation="wave" />
  </Box>
);

const SubCardLoader = () => {
  return (
    <Box sx={{ overflowX: "hidden", overflowY: "scroll" }}>
      <Skeleton height={"200px"} animation="wave" />
    </Box>
  );
};
export const ESkeletonPresets = {
  text: "text",
  paragraph: "paragraph",
  subCard: "subCard",
};
export const SkeletonLoaderPreset = ({
  variant = ESkeletonPresets.paragraph,
}) => {
  switch (variant) {
    case ESkeletonPresets.text:
      return <TextLoader />;
    case ESkeletonPresets.paragraph:
      return <ParagraphLoader />;
    case ESkeletonPresets.subCard:
      return <SubCardLoader />;
  }
  return <TextLoader />;
};
