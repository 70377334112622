import { useDispatch, useSelector } from "react-redux";
import {
  selectIsDarkMode,
  selectIsDesktopMode,
  selectModalState,
} from "../../../redux/selectors/appState.selectors";
import { updateModal } from "../../../redux/slices/application.slice";
import { IconButton, Modal, Paper, Slide, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  zIndex: 999999,
  // transform: "translate(-50%, -50%)",
  width: "500px",
  // height: "500px",
  overflowY: "scroll",
  overflowH: "hidden",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  backgroundColor: "rgb(255 255 255 / 88%)",
  backdropFilter: "blur(10px) saturate(1.5)",
  WebkitBackdropFilter: "blur(10px) saturate(1.5)",
};

export const CardModal = ({
  children,
  modalId,
  onClose = () => {},
  sheet = false,
  sx = {},
}) => {
  const { isOpen, data } = useSelector(selectModalState)[modalId];
  const isDesktopMode = useSelector(selectIsDesktopMode);
  const isDarkMode = useSelector(selectIsDarkMode);
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(
      updateModal({
        modalId,
        isOpen: false,
      })
    );

  return (
    <Modal
      sx={{ zIndex: 9999999 }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide direction="up" in={isOpen}>
        <Paper
          sx={{
            ...modalStyle,
            ...(isDesktopMode
              ? {
                  bottom: 0,
                  right: 0,
                }
              : {
                  width: "calc(100vw - 20px)",
                  // height: "30vh",
                  minHeight: "235px",
                  borderRadius: "15px",
                  margin: "10px",
                  boxSizing: "border-box",
                  bottom: "20px",
                  left: 0,
                }),
            ...(isDarkMode ? { backgroundColor: "rgb(0 0 0 / 88%)" } : {}),
            ...(sheet && !isDesktopMode
              ? {
                  bottom: "0px",
                  margin: "0px",
                  borderRadius: "23px 23px 0px 0px",
                  width: "100vw",
                }
              : {}),
            ...sx,
          }}
        >
          <Stack
            sx={{ zIndex: 1, position: "absolute", top: "12px", right: "25px" }}
            direction="row"
            justifyContent="flex-end"
          >
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          {children}
        </Paper>
      </Slide>
    </Modal>
  );
};
