import "./Post.css";

import {
  Comment,
  Image,
  Link,
  Subject,
  ThumbUp,
  Verified,
} from "@mui/icons-material";
import { ButtonBase, Divider, Paper, Stack } from "@mui/material";
import {
  checkIfContentIsNSFW,
  dayOfWeek,
  getColorForUpvotes,
  isPostVisited,
  stringToColour,
  timeStringClean,
} from "../../../helpers/functions";
import { useEffect, useState } from "react";

import { AwardBadgeList } from "../Award/AwardBadge/AwardBadge";
import { PostIcon } from "./components/PostIcon";
import { SubIcon } from "../Subreddit/SubIcon/SubIcon";
import { selectors } from "../../../redux";
import { useSelector } from "react-redux";
import { PostSub } from "./components/PostSub";
import { PostScore } from "./components/PostScore";
import { PostAwards } from "./components/PostAwards";
import { PostDate } from "./components/PostDate";
import { AuthorAvatar } from "../Profile/AuthorAvatar/AuthorAvatar";
import { PostAuthor } from "./components/PostAuthor";
import { PostCommentCount } from "./components/PostCommentCount";
import { PostMediaPreview } from "./components/PostMediaPreview";
import { BlockedPostBadge } from "../../ApplicationComponents/NSFWComponents/BlockedPostBadge";

export const Post = ({
  post,
  onClickThread,
  showSub = false,
  onClickSub = () => {},
}) => {
  const {
    title,
    ups,
    url,
    selftext,
    selftext_html,
    num_comments,
    author,
    created_utc,
    distinguished,
    stickied,
    subreddit,
    id,
    over_18,
  } = post;
  const _ups = Number(ups);

  const [isSelfTextExposed, setIsSelfTextExposed] = useState(false);
  const [isThumbnailEnlarged, setIsThumbnailEnlarged] = useState(false);
  const postHistory = useSelector(selectors.selectPostHistory);
  const [isAlreadyVisited, setIsAlreadyVisited] = useState(false);

  useEffect(() => {
    if (postHistory.length) {
      if (isPostVisited(postHistory, id)) {
        setIsAlreadyVisited(true);
      }
    }
  }, [postHistory]);
  const handleToggleSelfText = () => {
    setIsSelfTextExposed((prevState) => !prevState);
  };
  const handleToggleThumbnailPreview = (event) => {
    event.stopPropagation();
    setIsThumbnailEnlarged((prevState) => !prevState);
  };

  const renderSelfText = () => {
    if (isSelfTextExposed) {
      return (
        <span onClick={handleToggleSelfText} className="post-selftext exposed">
          <span dangerouslySetInnerHTML={{ __html: selftext_html }}></span>
        </span>
      );
    } else {
      return (
        <span onClick={handleToggleSelfText} className="post-selftext dimmed">
          <p>{selftext.slice(0, 100)}...</p>
        </span>
      );
    }
  };

  const getClassName = () => {
    let className = "post";

    if (distinguished) {
      className += " post-moderator";
    }
    if (stickied) {
      className += " post-stickied";
    }
    if (isAlreadyVisited) {
      className += " visited";
    }
    if (isThumbnailEnlarged || isSelfTextExposed) {
      className += " is-expanded";
    }

    return className;
  };

  if (checkIfContentIsNSFW(subreddit.toLowerCase(), over_18)) {
    return (
      <Paper>
        <BlockedPostBadge />
      </Paper>
    );
  }

  return (
    <>
      <Paper className={getClassName()} elevation={0}>
        {showSub && (
          <PostSub onClick={() => onClickSub(subreddit)} thread={post} />
        )}
        <Stack padding={"5px 5px 0px 5px"} gap="8px" direction={"row"}>
          <div className="icon-container">
            <PostIcon
              onTogglePreview={handleToggleThumbnailPreview}
              post={post}
            />
          </div>
          <div className="content-container">
            <ButtonBase sx={{ width: "100%", borderRadius: "20px" }}>
              <header
                className={`post-header${selftext ? " selftext" : ""}`}
                onClick={onClickThread}
              >
                <span className="post-title">{title}</span>
                <div className="post-engagement-data">
                  <PostAwards thread={post} />
                  <PostScore thread={post} />
                </div>
              </header>
            </ButtonBase>
            {selftext && renderSelfText()}
          </div>
        </Stack>
        {isThumbnailEnlarged && <PostMediaPreview thread={post} />}
        <Stack
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ fontSize: "0.5em", padding: "2px 10px" }}
          direction={"row"}
          className="post-metadata"
        >
          <PostAuthor thread={post} />
          <PostDate thread={post} />{" "}
          {distinguished && <span className="post-chip-mod">MOD</span>}{" "}
          <PostCommentCount thread={post} />
        </Stack>
      </Paper>
      <Divider />
    </>
  );
};
