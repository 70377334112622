import { Box, Button, IconButton, Paper, Typography } from "@mui/material";

import { ErrorOutline } from "@mui/icons-material";

export const ErrorMessageDisplay = ({ message, onRetry }) => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    flexDirection: "column",
    padding: "20px",
    gap: "20px",
    // backgroundColor: '#f7f7f7',
    borderRadius: "20px",
    maxWidth: "90%",
    width: "500px",
    margin: "0 auto",
    marginTop: "10px",
  };
  return (
    <Paper sx={style}>
      <IconButton onClick={onRetry}>
        <ErrorOutline sx={{ fontSize: "50px" }} />
      </IconButton>
      <Typography color="crimson" variant="h1" fontSize={"20px"}>
        Something went wrong
      </Typography>
      <Typography variant="caption">{message}</Typography>
      <Button onClick={onRetry}>Retry</Button>
    </Paper>
  );
};
