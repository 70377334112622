import { useEffect, useState } from "react";
import {
  ESkeletonPresets,
  SkeletonLoaderPreset,
} from "../../../ApplicationComponents/SkeletonLoaderPreset/SkeletonLoaderPreset";
import { Chip, Stack, Typography, Box, ButtonBase } from "@mui/material";
import { fetchSubredditData } from "../../../../helpers/functions";
import { ErrorMessageDisplay } from "../../../ApplicationComponents/ErrorMessageDisplay/ErrorMessageDisplay";
import { styleHelper } from "../../../../styles/styles";
import { AccountCircle } from "@mui/icons-material";

export const SubLiveCard = ({ sub, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const loadSub = () => {
    fetchSubredditData(sub)
      .then((data) => {
        console.log("got data", data);
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(true);
      });
  };

  useEffect(() => {
    loadSub();
  }, [sub]);

  if (isLoading) {
    return <SkeletonLoaderPreset variant={ESkeletonPresets.subCard} />;
  }

  if (error) {
    return (
      <ErrorMessageDisplay message={"Unable to load sub"} onRetry={loadSub} />
    );
  }

  if (!data || !data?.details) {
    return null;
  }

  return (
    <ButtonBase
      TouchRippleProps={{
        sx: { borderRadius: "10px", zIndex: 3 },
      }}
      onClick={() => setTimeout(onClick, 500)}
    >
      <Stack
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
          width: "300px",
          height: "190px",
          borderRadius: "10px",
          border: `1px solid rgb(103 103 103)`,
          overflow: "hidden",
          flexShrink: "0",
        }}
      >
        <Stack
          sx={{
            background: `url(${data.details.artwork.background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "10px",
            position: "relative",
            justifyContent: "center",
            borderBottom: `1px solid rgb(103 103 103)`,
            flexShrink: "0",
          }}
        >
          <Stack
            sx={{
              gap: "10px",
              zIndex: 2,
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                width: "60px",
                height: "60px",
                overflow: "hidden",
                borderRadius: "10px",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={data.details.artwork.icon}
              />
            </Box>
            <Stack sx={{ gap: "10px", alignItems: "center" }} direction={"row"}>
              <Stack sx={{ gap: "5px" }}>
                <Stack sx={{ alignItems: "center" }} direction={"row"}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
                    }}
                  >
                    {data.details.name}
                  </Typography>
                  <Chip
                    sx={{
                      background: "#40c05754",
                      scale: "0.8",
                    }}
                    size={"small"}
                    avatar={
                      <AccountCircle
                        sx={{ color: "#40c057!important", fontSize: "10px" }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ color: "#40c057", fontWeight: "bold" }}
                        variant={"caption"}
                      >
                        {Number(data.details.subscribers).toLocaleString()}
                      </Typography>
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Box
            sx={{
              ...styleHelper.posAbsolute100PercentStyles(),
              display: "flex",
              justifyContent: "flex-end",
              padding: "5px 10px",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "0.65rem", opacity: 0.5 }}
            >
              SINCE {new Date(data.details.dateCreated).getFullYear()}
            </Typography>
          </Box>

          <Box
            sx={{
              ...styleHelper.posAbsolute100PercentStyles(),
              background: styleHelper.linearGradientGenerator(
                "transparent",
                "black",
                "180deg"
              ),
            }}
          ></Box>
        </Stack>
        <Stack
          sx={{
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            gap: "5px",
          }}
        >
          <Chip
            sx={{ width: "fit-content" }}
            size={"small"}
            label={
              <Typography
                sx={{
                  fontSize: "10px",
                  fontFamily: styleHelper.EFontFamily.CoinbaseText,
                  color: "white",
                }}
                variant={"caption"}
              >
                {data.details.title}
              </Typography>
            }
          />
          <Typography
            sx={{
              opacity: 0.75,
              color: "white",
              paddingLeft: "5px",
              textAlign: "left",
              paddingRight: "5px",
            }}
            variant={"caption"}
          >
            {data.details.subtitle}
          </Typography>
        </Stack>
      </Stack>
    </ButtonBase>
  );
};
