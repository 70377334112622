import { Box, Typography } from "@mui/material";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { THEME_MODES } from "../../../config";
import { selectors } from "../../../redux";

export const RawJSONView = ({ jsonText }) => {
  const isDarkMode = useSelector(selectors.selectTheme) === THEME_MODES.dark;
  const style = {}; // = { maxHeight: "100vh", overflowY: "scroll" };
  return (
    <Box sx={style}>
      <ReactJson src={jsonText} theme={isDarkMode ? "monokai" : undefined} />
    </Box>
  );
};
