import { Comment } from "../Comment";

export const CommentChildren = ({ replies, isVisible, opUsername }) => {
  if (!replies) return;

  return (
    <div
      className={`comment-children${isVisible ? "" : " comment-thread-hidden"}`}
    >
      {replies?.data?.children?.map((child, index) => {
        const { data } = child;
        return <Comment key={index} comment={data} opUsername={opUsername} />;
      })}
    </div>
  );
};
