import { Button, Text } from "@mantine/core";
import { Link } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export const CommentMediaGIF = ({ comment }) => {
  if (!comment["media_metadata"]) return null;

  if (comment["media_metadata"]) {
    for (const objKey of Object.keys(comment["media_metadata"])) {
      const _image = comment["media_metadata"][objKey]["s"];

      if (!_image.gif) return null;
      return (
        <Stack gap={"10px"} padding={"10px"}>
          <img
            style={{ width: "100%", borderRadius: "10px" }}
            src={_image.gif}
          />
          <Button
            size="xs"
            href={_image.gif}
            target="_blank"
            component="a"
            variant="light"
            leftIcon={<Link />}
          >
            Open
          </Button>
          <Typography
            align="center"
            sx={{
              fontSize: "12px",
              opacity: 0.1,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            This comment contains an embedded GIF.
          </Typography>
        </Stack>
      );
    }
  }

  return null;
};
