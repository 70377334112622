import { Stack, Typography } from "@mui/material";
import { styleHelper } from "../../../../styles/styles";
import { DomainChip } from "../../../ApplicationComponents/DomainChip/DomainChip";

export const ThreadDomain = ({ domain }) => {
  if (!domain) return null;

  const favicon = `https://www.google.com/s2/favicons?domain=${domain}&sz=128`;
  const size = "15px";

  return <DomainChip domain={domain} favIcon={favicon} />;
  return (
    <Stack gap={"5px"} direction={"row"} alignItems={"center"}>
      <img
        draggable={"false"}
        style={{
          width: size,
          height: size,
          borderRadius: "3px",
          pointerEvents: "none",
        }}
        src={favicon}
      />
      <Typography
        variant={"caption"}
        sx={{
          fontWeight: "bold",
          fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
          userSelect: "none",
          opacity: 0.65,
        }}
      >
        {domain}
      </Typography>
    </Stack>
  );
};
