import { Box, ButtonBase, Divider, Stack } from "@mui/material";
import { stringToColour } from "../../../../helpers/functions";
import { AuthorAvatar } from "../../Profile/AuthorAvatar/AuthorAvatar";
import { CommentScore } from "./CommentScore";
import { AwardBadgeList } from "../../Award/AwardBadge/AwardBadge";
import { ArrowUpward } from "@mui/icons-material";

export const CommentHeader = ({
  comment,
  onCollapse,
  onClickProfile,
  isAutoMod,
  isOP,
  isVisible,
  authorFlairProps,
}) => {
  const isDeleted = !comment.author;
  const color = stringToColour(comment.author);
  return (
    <Stack>
      <ButtonBase sx={{ width: "100%" }}>
        <header
          data-comment={comment.id}
          className={`comment-header`}
          onClick={onCollapse}
        >
          <Stack gap={"2px"} direction={"row"} alignItems="center">
            <span
              className={`comment-author ${isOP ? "comment-is-op" : ""} ${
                isAutoMod ? "comment-is-auto-mod" : ""
              }`}
              style={{ color }}
            >
              <Stack gap={"10px"} direction={"row"} alignItems={"center"}>
                {!isDeleted && <CommentScore comment={comment} />}

                {comment.author && (
                  <AuthorAvatar
                    onClick={onClickProfile}
                    hideUsername={comment.depth > 5}
                    username={comment.author}
                    isOP={isOP}
                    flair={authorFlairProps}
                    AuthorFlairProps={{
                      truncate: true,
                      truncateLimit: comment.depth > 1 ? 2 : undefined,
                    }}
                    TypographyProps={{
                      fontSize: "14px",
                    }}
                    imgProps={{
                      style: {
                        width: "20px",
                        height: "20px",
                        ...(isVisible
                          ? {}
                          : {
                              filter: "grayscale(1)",
                              opacity: "0.5",
                            }),
                      },
                    }}
                    GradientTextProps={
                      isVisible
                        ? {}
                        : {
                            color1: "gray",
                            color2: "#8a8a8a",
                          }
                    }
                  />
                )}
              </Stack>
            </span>
            {!isDeleted && (
              <Stack direction={"row"} alignItems={"center"}>
                <Stack
                  sx={{ marginLeft: "5px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <AwardBadgeList
                    awards={comment?.all_awardings}
                    awardProps={{}}
                  />{" "}
                </Stack>
              </Stack>
            )}
          </Stack>
        </header>
      </ButtonBase>

      {isVisible && <Divider sx={{ opacity: 0.2 }} color={color} />}
    </Stack>
  );
};
