import {
  convertHexToRgbaTranparency,
  getAuthorFlairProps,
  replaceBodyURLs,
  stringToColour,
} from "../../../helpers/functions";

export const getCommentData = (comment) => {
  const {
    author,
    author_fullname,
    body,
    body_html,
    created_utc,
    ups,
    score,
    replies,
    parent_id,
    id,
    depth,
    subreddit,
    subreddit_id,
    all_awardings,
    is_submitter,
    stickied,
    author_premium,
    subreddit_type,
    permalink,
    controversiality,
    send_replies,
    author_is_blocked,
    banned_by,
    mod_reason_by,
    banned_at_utc,
    locked,
    score_hidden,
  } = comment;

  const dateCreated = new Date(created_utc * 1000); // Multiply by 1000 to convert from seconds to milliseconds
  const color = stringToColour(author);
  const isEmpty = !body || body === "[removed]";
  const HTML = isEmpty ? "" : body_html;

  return {
    id: {
      comment: id,
      subreddit: subreddit_id,
      parent: parent_id,
      commentIdWithParent: `${parent_id}/${id}`,
    },
    depth,
    link: {
      permalink,
    },
    subreddit: {
      name: subreddit,
      id: subreddit_id,
      viewType: subreddit_type,
    },
    colors: {
      // TODO: Change primary color based on light/dark mode
      primary: { light: color, dark: color },
      secondary: {
        light: color + "19",
        dark: convertHexToRgbaTranparency(color, 0.15),
      },
    },
    dateCreated: {
      date: dateCreated,
      string: (() => {
        if (!(dateCreated instanceof Date && !isNaN(dateCreated))) return "";
        return (
          dateCreated.toLocaleDateString() +
          " " +
          dateCreated.toLocaleTimeString()
        );
      })(),
    },
    replies,
    score: {
      ups,
      controversiality,
    },
    body: {
      body,
      HTML,
    },
    author: {
      username: author,
      flair: getAuthorFlairProps(comment),
      id: author_fullname,
    },
    awards: all_awardings,
    authorFlairProps: getAuthorFlairProps(comment),
    banned: {
      bannedBy: banned_by,
      modReason: mod_reason_by,
      bannedDate: banned_at_utc ? new Date(banned_at_utc * 1000) : null,
    },
    bool: {
      isBanned: !!banned_at_utc,
      isLocked: !!locked,
      isScoreHidden: !!score_hidden,
      isBlocked: !!author_is_blocked,
      isFirstComment: depth === 0,
      isLastComment: !replies,
      isGifEmbed: !!comment?.body?.includes("![gif]("),
      isAutoMod: author === "AutoModerator",
      isEmpty,
      isOP: !!is_submitter,
      isStickied: !!stickied,
      isPremiumMember: !!author_premium,
      isControversial: !!controversiality,
      isSendRepliesEnabled: !!send_replies,
      hasBlockquote: HTML.includes("<blockquote>"),
    },
    _raw: comment,
  };
};
