import "../styles/variables.css";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { actions, selectors } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Container } from "./Container/Container";
import { CustomRedditURLModal } from "../components/Modals/CustomRedditURLModal/CustomRedditURLModal";
import { HomeView } from "../components/Views/HomeView/HomeView";
import { SubDrawer } from "../components/Drawers/SubDrawer/SubDrawer";
import { ThreadDrawer } from "../components/Drawers/ThreadDrawer/ThreadDrawer";
import { updateModal } from "../redux/slices/application.slice";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  const activeSub = useSelector(selectors.selectActiveSub);
  const activeThread = useSelector(selectors.selectActiveThread);
  const isCustomThreadModalOpen = useSelector(
    selectors.selectCustomThreadModalOpen
  );

  const handleClickSub = (sub) => {
    // dispatch(actions.setActiveSub(sub));
    dispatch(
      updateModal({
        modalId: "subredditModal",
        isOpen: true,
        data: {
          sub,
        },
      })
    );
  };

  const handleClickThread = (thread) => {
    dispatch(actions.setActiveThread(thread));
  };

  const handleCloseThread = () => {
    dispatch(actions.setActiveThread(null));
  };

  const handleGoHome = () => {
    dispatch(actions.setActiveSub(null));
  };

  const handleWindowSizeChange = () => {
    // setMobile(window.innerWidth <= 500);
    dispatch(actions.setIsDesktopMode(window.innerWidth >= 500));
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Container
        handleGoHome={handleGoHome}
        activeSub={activeSub}
        onClickSub={handleClickSub}
        onClickThread={handleClickThread}
      >
        <HomeView
          onClickSub={handleClickSub}
          activeThread={activeThread}
          onClickThread={handleClickThread}
        />
        <SubDrawer onClose={handleGoHome} onClickThread={handleClickThread} />
        <ThreadDrawer onClose={handleCloseThread} />
      </Container>
    </QueryClientProvider>
  );
}

export default App;
