import { useEffect, useState } from "react";
import "./SubredditView.css";
import {
  SORT_METHOD_KEYS,
  stringToColour,
  SUB_SORT_METHODS,
} from "../../../helpers/functions";
import { getSubreddit } from "../../../api/base";
import { Post } from "../../RedditComponents/Post/Post";
import { FullWidthLoader } from "../../Loaders/FullWidthLoader/FullWidthLoader";
import { SubQuickActions } from "../../RedditComponents/Subreddit/SubQuickActions/SubQuickActions";
import { SubCard } from "../../RedditComponents/Subreddit/SubCard/SubCard";
import { ErrorMessageDisplay } from "../../ApplicationComponents/ErrorMessageDisplay/ErrorMessageDisplay";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../../redux";
import { Portal } from "@mui/material";
import { updateModal } from "../../../redux/slices/application.slice";

export const SubredditView = ({ sub, onClickThread }) => {
  const subColor = stringToColour(sub);
  const dispatch = useDispatch();
  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);
  const [posts, setPosts] = useState([]);
  const [sortMethod, setSortMethod] = useState(SORT_METHOD_KEYS.DEFAULT);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const handleUpdateSortMethod = (str) => setSortMethod(str);

  const handleOpenSubModal = () => {
    dispatch(
      updateModal({
        modalId: "subredditModal",
        data: {
          sub,
        },
        isOpen: true,
      })
    );
  };
  const handleFetchSubPosts = async () => {
    setIsLoading(true);
    getSubreddit(sub)
      .then((data) => {
        setPosts(data);
        setIsLoading(false);
      })
      .catch((err) => setIsError(true));
  };

  useEffect(() => {
    handleFetchSubPosts();
  }, [sub]);

  return (
    <div
      className="subContainer"
      style={{ overflowY: isLoading ? "hidden" : "scroll" }}
    >
      <div className="header-container">
        <SubCard
          onClick={handleOpenSubModal}
          showAddToFavorites
          sub={sub}
          style={{ borderRadius: "0px", marginBottom: 0 }}
        />
      </div>
      {isError && (
        <ErrorMessageDisplay
          onRetry={handleFetchSubPosts}
          message={`Error fetching posts for r/${sub}`}
        />
      )}
      {isLoading && !isError && <FullWidthLoader />}
      {!isLoading && (
        <div
          className="sub-posts"
          style={isDesktopMode ? { marginBottom: "0px" } : {}}
        >
          <Portal>
            <SubQuickActions
              onChangeSort={handleUpdateSortMethod}
              sortMethod={sortMethod}
            />
          </Portal>
          {posts
            .filter((a) => a.stickied)
            .map((post) => (
              <Post
                key={post.name}
                post={post}
                onClickThread={() => onClickThread(post)}
              />
            ))}
          {posts
            .filter((a) => !a.stickied)
            .sort(SUB_SORT_METHODS[sortMethod])
            .map((post) => (
              <Post
                key={post.name}
                post={post}
                onClickThread={() => onClickThread(post)}
              />
            ))}
        </div>
      )}
    </div>
  );
};
