import { Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { selectModalState } from "../../../redux/selectors/appState.selectors";
import { styleHelper } from "../../../styles/styles";
import { AwardBadgeList } from "../../RedditComponents/Award/AwardBadge/AwardBadge";
import { CardModal } from "../../ApplicationComponents/CardModal/CardModal";

export const AwardModal = () => {
  const { data } = useSelector(selectModalState).awardModal;

  const getModalTitle = (length) => {
    if (length === 1) return "1 Award";

    return `${length} Awards`;
  };

  return (
    <CardModal modalId={"awardModal"}>
      {data && (
        <Stack>
          <Typography
            sx={{
              fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            {getModalTitle(data.length)}
          </Typography>
          <AwardBadgeList
            sx={{
              flexWrap: "wrap",
              gap: "50px",
              maxHeight: "70vh",
              overflow: "auto",
            }}
            awards={data}
            renderAll
            awardProps={{
              size: "80px",
              showCount: true,
            }}
            disableClick
          />
        </Stack>
      )}
    </CardModal>
  );
};
