import { CircularProgress } from "@mui/material";
import { PostLoader } from "../PostLoader/PostLoader";
import "./FullWidthLoader.css";

export const FullWidthLoader = ({}) => {
  return (
    <div className="full-width-loader">
      {new Array(7).fill(null).map((a, index) => (
        <PostLoader key={index} />
      ))}
    </div>
  );
};
