import { getCommentData } from "../commentHelper";
import { Box, Stack, Typography } from "@mui/material";
import { CommentMediaGIF } from "./CommentMediaGIF";
import { Text } from "@mantine/core";
import { CommentMenu } from "./CommentMenu";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../../redux/selectors/appState.selectors";
import { CommentBodyHTML } from "./CommentBodyHTML";

export const CommentBody = ({
  comment,
  onClickCollapseBar,
  onCollapseComment,
  isVisible,
}) => {
  const isDarkMode = useSelector(selectIsDarkMode);
  // Get Comment Props
  const commentData = getCommentData(comment);

  return (
    <>
      <CollapseBar onClick={onClickCollapseBar} />
      <Box
        className={`comment-body${isVisible ? "" : " comment-thread-hidden"}`}
        data-theme={isDarkMode ? "dark" : "light"}
      >
        {commentData.bool.isGifEmbed && <CommentMediaGIF comment={comment} />}
        {!commentData.bool.isGifEmbed && <CommentBodyHTML comment={comment} />}
        {commentData.bool.isEmpty && (
          <Typography
            sx={{
              paddingTop: "15px",
              fontSize: "0.75rem",
              opacity: 0.5,
              fontFamily: "CoinbaseText",
              textAlign: "center",
            }}
            color={"secondary"}
          >
            Comment was Removed
          </Typography>
        )}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <p style={{ opacity: 0.3, fontSize: "0.75rem" }}>
            {commentData.dateCreated.string}
          </p>
          {!commentData.bool.isEmpty && commentData.author.username && (
            <CommentMenu onCollapse={onCollapseComment} comment={comment} />
          )}
        </Stack>
      </Box>
    </>
  );
};

const CollapseBar = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "15px",
        height: "100%",
        display: "grid",
        placeItems: "center",
        cursor: "pointer",
        "&:hover .collapse-bar": {
          background: "rgba(0,0,0,0.25)",
        },
      }}
    >
      <Box
        className={"collapse-bar"}
        sx={{
          width: "50%",
          height: "calc(100% - 20px)",
          background: "rgba(0,0,0,0.03)",
          borderRadius: "5px",
        }}
      ></Box>
    </Box>
  );
};
