import { getColorForUpvotes } from "../../../../helpers/functions";
import { ThumbUp, Verified } from "@mui/icons-material";

export const PostScore = ({ thread }) => {
  const { ups } = thread;
  const _ups = Number(ups);

  const getUpIcon = () => {
    const _sx = {
      color: getColorForUpvotes(_ups),
      fontSize: "14px",
    };
    if (_ups > 500) {
      return <Verified sx={_sx} />;
    }

    return <ThumbUp sx={_sx} />;
  };

  return (
    <span className="post-ups">
      <span
        className="count"
        style={{
          color: getColorForUpvotes(ups),
        }}
      >
        {ups}
      </span>{" "}
      {getUpIcon()}
    </span>
  );
};
