import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ThreadTitle } from "./components/ThreadTitle";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { ThreadSelfText } from "./components/ThreadSelfText";
import { CommentRenderer } from "../../RedditComponents/Comment/CommentRenderer/CommentRenderer";
import { ThreadMedia } from "./components/ThreadMedia";
import { ThreadImage } from "./components/ThreadImage";
import { ThreadActions } from "./components/ThreadActions";
import { OpenGraphCard } from "../../ApplicationComponents/OpenGraphCard/OpenGraphCard";

const getThreadData = (data, index = 0) => {
  if (data && data[index]?.children[0]?.data) {
    return data[index]?.children[0]?.data;
  } else {
    return {};
  }
};

const getThreadComments = (data) => {
  if (!data || !data[1] || !data[1]?.children) return [];

  return data[1].children;
};
export const ThreadView = ({ permalink }) => {
  const { isLoading, error, data } = useQuery({
    queryKey: [permalink],
    queryFn: async () => {
      const response = await fetch(
        permalink.replace(".media/reddit/r/", ".media/reddit/api/r/")
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = response.json();

      return data;
    },
  });

  // useEffect(() => {
  //   if (data) {
  //    // console.log(`Got thread data: ${getThreadData(data)?.title}`, data);
  //     // setThreadData(data[0]?.children[0]?.data);
  //     // console.log(`Got comments:`, getThreadComments(data));
  //   }
  // }, [data]);

  //if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  if (isLoading) {
    return (
      <Stack
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          maxWidth: "100%",
        }}
      >
        <ThreadTitle
          title={"Loading"}
          subreddit={"loading"}
          isLoading={isLoading}
        />
        <Box sx={{ padding: "10px 15px" }}>
          <Skeleton sx={{ transform: "none" }} height={"170px"} />
        </Box>
        <Box sx={{ padding: "0px 15px" }}>
          <CommentRenderer comments={[]} isLoading={true} opUsername={""} />
        </Box>
      </Stack>
    );
  }

  return (
    <Stack sx={{ overflowY: "scroll", overflowX: "hidden" }}>
      <ThreadTitle
        title={getThreadData(data)?.title ?? "Loading"}
        subreddit={getThreadData(data)?.subreddit ?? "loading"}
        isLoading={isLoading}
        awards={getThreadData(data)?.all_awardings ?? []}
        domain={getThreadData(data)?.domain ?? ""}
      />
      <Stack
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          padding: "20px",
          paddingBottom: "80px",
        }}
      >
        {getThreadData(data)?.media && (
          <ThreadMedia post={getThreadData(data)} />
        )}
        {getThreadData(data)?.thumbnail && (
          <ThreadImage post={getThreadData(data)} />
        )}
        {getThreadData(data)["author"] && (
          <ThreadActions thread={getThreadData(data)} />
        )}
        {getThreadData(data)["url_overridden_by_dest"] && (
          <Stack padding={"10px"}>
            <OpenGraphCard
              url={getThreadData(data)["url_overridden_by_dest"]}
            />
          </Stack>
        )}
        <ThreadSelfText
          selfTextHTML={getThreadData(data)["selftext_html"] ?? ""}
          author={getThreadData(data)["author"] ?? ""}
        />
        <CommentRenderer
          comments={getThreadComments(data)}
          isLoading={isLoading}
          opUsername={getThreadData(data)["author"] ?? "-"}
        />
        {/*<RawJSONView jsonText={getThreadData(data)} />*/}
        {getThreadComments(data).length === 0 && (
          <Typography sx={{ opacity: 0.5 }} align={"center"}>
            There are no comments yet.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
