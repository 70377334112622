import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../../redux";
import { IconButton, Tooltip } from "@mui/material";
import { THEME_MODES } from "../../../config";
import { Brightness3, LightMode } from "@mui/icons-material";

export const ThemeToggleButton = () => {
  const dispatch = useDispatch();
  const activeTheme = useSelector(selectors.selectTheme);
  const handleToggleTheme = () => {
    dispatch(actions.toggleTheme());
  };

  return (
    <Tooltip title="Toggle Theme">
      <IconButton onClick={handleToggleTheme}>
        {activeTheme === THEME_MODES.light && <LightMode />}
        {activeTheme === THEME_MODES.dark && <Brightness3 />}
      </IconButton>
    </Tooltip>
  );
};
