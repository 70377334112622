import { Link, Subject } from '@mui/icons-material';

export const PostIcon = ({ post, onTogglePreview }) => {
  const renderThumbnail = () => {
    return (
      <div className="post-thumbnail-container">
        <img
          onClick={onTogglePreview}
          src={post.thumbnail}
          className="post-thumbnail"
        />
      </div>
    );
  };

  if (post.isImage)
    return <span className="post-icon">{renderThumbnail()}</span>;
  if (post.selftext)
    return (
      <span className="post-icon">
        <Subject />
      </span>
    );
  return (
    <span className="post-icon">
      <Link />
    </span>
  );
};

//<span className="post-icon">{renderIcon()}</span>
