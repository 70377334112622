import { Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";

export const PostMediaPreview = ({ thread }) => {
  const { url } = thread;
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Fade in={fadeIn}>
      <Box className="post-photo-container">
        <img src={url} className="post-photo" />
      </Box>
    </Fade>
  );
};
