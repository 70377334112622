import { AuthorAvatar } from "../../Profile/AuthorAvatar/AuthorAvatar";
import { getAuthorFlairProps } from "../../../../helpers/functions";

export const PostAuthor = ({ thread }) => {
  const { author, subreddit } = thread;
  const authorFlairProps = getAuthorFlairProps(thread);

  return (
    <AuthorAvatar
      sx={{
        opacity: 0.5,
        filter: "grayscale(1)",
      }}
      hideProfileImage
      TypographyProps={{
        fontSize: "10px",
      }}
      AuthorFlairProps={authorFlairProps}
      username={author}
      subreddit={subreddit}
      openProfileModalOnClick
    />
  );
};
