import { useEffect, useState } from "react";
import { getOpenGraphData } from "../../../api/base";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { SkeletonLoaderPreset } from "../SkeletonLoaderPreset/SkeletonLoaderPreset";
import { ErrorMessageDisplay } from "../ErrorMessageDisplay/ErrorMessageDisplay";
import { CONSTANTS } from "../../../config";

export const OpenGraphCard = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);

  const handleFetchWebsiteData = () => {
    getOpenGraphData(url)
      .then((_data) => {
        console.log("Got link data: ", _data);
        setData(_data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetchWebsiteData();
  }, []);

  for (const domain of CONSTANTS.ignoreSourceDomains) {
    if (url.includes(domain)) return null;
  }

  return (
    <Stack>
      {isLoading && <SkeletonLoaderPreset variant={"paragraph"} />}
      {error && (
        <ErrorMessageDisplay
          message={"Unable to get website info."}
          onRetry={handleFetchWebsiteData}
        />
      )}
      {!isLoading && !error && (
        <Stack sx={{ gap: "10px" }}>
          {data.image && (
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "20px",
                "& img": {
                  width: "100%",
                  pointerEvents: "none",
                },
              }}
            >
              <img src={data.image} />
            </Box>
          )}
          <Typography fontWeight={"bold"}>{data.title}</Typography>
          <Typography variant={"caption"} sx={{ opacity: 0.5 }}>
            {data.description}
          </Typography>
          <Button target={"_blank"} href={url}>
            Visit link
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
