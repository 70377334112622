import { Badge, Box, ButtonBase, Chip, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../../../redux/slices/application.slice";
import { uiSelectors } from "../../../../redux";

export const AwardBadge = ({
  award,
  sx = {},
  size = "20px",
  showCount = false,
}) => {
  const {
    id,
    icon_url,
    is_new,
    description,
    name,
    resized_static_icons,
    count,
  } = award;
  //const iconSmallURL = resized_static_icons.find((icon) => icon.width === 64);
  return (
    <Stack
      marginTop={showCount ? "10px" : undefined}
      sx={sx}
      alignItems="center"
    >
      <Badge color={"primary"} badgeContent={showCount ? count : 0}>
        <img
          width={size}
          draggable="false"
          alt={name + ": " + description}
          src={icon_url}
        />
      </Badge>

      {showCount && <Chip sx={{ marginTop: "10px" }} label={name} />}
    </Stack>
  );
};

export const AwardBadgeList = ({
  awards = [],
  sx = {},
  awardProps = {},
  renderAll = false,
  disableClick = false,
}) => {
  const isEnabled = useSelector(uiSelectors.global.selectAwards).isEnabled;

  const dispatch = useDispatch();

  if (!awards.length || !isEnabled) return null;

  const renderLimit = 4;
  const maxLimit = awards.length;

  const handleOpenModal = (e) => {
    e.stopPropagation();

    setTimeout(() => {
      dispatch(
        updateModal({
          modalId: "awardModal",
          isOpen: true,
          data: awards,
        })
      );
    }, 300);
  };

  return (
    <ButtonBase
      component={"div"}
      disableRipple={disableClick}
      disableTouchRipple={disableClick}
      sx={{ padding: "3px", borderRadius: "3px" }}
    >
      <Stack onClick={disableClick ? undefined : handleOpenModal}>
        <Stack
          sx={{
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "center",
            ...sx,
          }}
          gap={"5px"}
          direction="row"
        >
          {awards
            .slice(0, renderAll ? maxLimit : renderLimit)
            .map((award, index) => (
              <AwardBadge
                sx={renderAll ? { padding: "10px" } : {}}
                award={award}
                key={index}
                {...awardProps}
              />
            ))}
        </Stack>
        {awards.length > 4 && !renderAll && (
          <Typography fontWeight={800} variant="caption" color={"primary"}>
            +{awards.length - 4}
          </Typography>
        )}
      </Stack>
    </ButtonBase>
  );
};
