import { configureStore } from "@reduxjs/toolkit";
import { applicationRedux } from "./slices/application.slice";
import { settingsRedux } from "./slices/settings.slice";

export const store = configureStore({
  reducer: {
    [applicationRedux.namespace]: applicationRedux.reducer,
    [settingsRedux.namespace]: settingsRedux.reducer,
  },
});
