const drawerStyles = {
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(10px) saturate(1.5)",
    WebkitBackdropFilter: "blur(10px) saturate(1.5)",
  },
  "& .MuiDrawer-paper.MuiPaper-root": {
    width: "100vw",
    maxWidth: "550px",
    overflow: "hidden",
  },
};

const blurBackgroundStyles = (weight = "10px") => {
  const value = `blur(${weight}) saturate(160%)`;
  return {
    backdropFilter: value,
    WebkitBackdropFilter: value,
  };
};

const linearGradientGenerator = (start, stop, angle) => {
  return `linear-gradient(${angle}, ${start}, ${stop})`;
};

const posAbsolute100PercentStyles = () => {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
  };
};

const EFontFamily = {
  CoinbaseDisplay: "CoinbaseDisplay, sans-serif",
  CoinbaseText: "CoinbaseText, sans-serif",
};
const styleHelper = {
  blurBackgroundStyles,
  EFontFamily,
  linearGradientGenerator,
  posAbsolute100PercentStyles,
};

export { drawerStyles, styleHelper };
export const blockquoteStyles = (isDarkMode) => ({
  "& blockquote": {
    margin: "0",
  },
  "& blockquote p": {
    opacity: "0.6",
    fontFamily: "'CoinbaseText'!important",
    borderLeft: `2px solid ${isDarkMode ? "black" : "gray"}`,
    paddingLeft: "1em",
    paddingTop: "5px",
    paddingRight: "5px",
    paddingBottom: "5px",
    background: `linear-gradient(90deg, ${
      isDarkMode ? "#353535" : "#e7e7e7"
    }, transparent)`,
  },
});
export const codeStyles = (isDarkMode) => ({
  "& code, kbp, samp": {
    fontFamily: "monospace",
    fontWeight: "bold",
    display: "inline-block",
    padding: "2px 8px",
    background: "linear-gradient(45deg, #030c39, transparent)",
    borderRadius: "9px",
    lineHeight: "1.95em",
    border: "1px solid #005c7f",
    color: "#00b8ff",
    fontSize: "0.75rem",
    boxShadow: "0 0 6px rgb(0 0 0 / 35%)",
    maxWidth: "100%",
    overflowX: "auto",
    transform: "scale(0.98)",
    transformOrigin: "bottom",
  },
});

export const subredditDescriptionStyles = () => ({
  "& h1, h2, h3, h4, h5, h6": {
    margin: 0,
  },
  "& blockquote": {
    margin: 0,
  },
  "& ul": {
    listStyle: "none",
    padding: 0,
  },
  "& ol": {
    paddingLeft: "20px",
  },
});
