import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "../../../../redux/selectors/appState.selectors";
import { styleHelper } from "../../../../styles/styles";

export const AuthorFlair = ({
  flair,
  sx = {},
  truncate = false,
  truncateLimit = 3,
  imageFlairSize,
  ImageFlairStyle = {},
}) => {
  if (!flair) return null;
  const { author_flair_text, author_flair_richtext } = flair;
  if (
    !author_flair_text ||
    !author_flair_text.trim() ||
    author_flair_text === "\u200B"
  )
    return (
      <Typography variant={"caption"} sx={{ opacity: 0.5 }}>
        This user has no flair.
      </Typography>
    );

  const authorFlairs = author_flair_text.split(" | ");
  const renderedFlairs = authorFlairs.slice(
    0,
    truncate ? truncateLimit : authorFlairs.length
  );
  const hasMore = authorFlairs.length > renderedFlairs.length;
  let difference = 0;
  if (hasMore) {
    difference = authorFlairs.length - renderedFlairs.length;
  }

  if (author_flair_richtext) {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={sx}
        gap={"10px"}
        flexWrap={"wrap"}
        direction="row"
      >
        {author_flair_richtext.map((flair, index) => (
          <RichFlair
            flair={flair}
            key={index}
            imageFlairSize={imageFlairSize}
            ImageFlairStyle={ImageFlairStyle}
          />
        ))}
      </Stack>
    );
  }
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={sx}
      gap={"10px"}
      flexWrap={"wrap"}
      direction="row"
    >
      {renderedFlairs.map((text, index) => (
        <FlairBadge key={index} text={text} />
      ))}
      {hasMore && (
        <Stack>
          <Typography
            sx={{
              fontFamily: styleHelper.EFontFamily.CoinbaseText,
              fontWeight: "bold",
            }}
            color={"primary"}
          >
            +{difference}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

const FlairBadge = ({ text }) => {
  const isDarkMode = useSelector(selectIsDarkMode);

  const lightStyles = {
    background: "linear-gradient(45deg, #dddddd, white)",
    border: "1px solid #cdcdcd",
  };

  const darkStyles = {
    border: "1px solid #17c600",
    background: "linear-gradient(45deg, rgb(89 255 0 / 49%), #ffffff00)",
    color: "#17c600",
    fontWeight: "bold",
    boxShadow: "0 0 20px rgb(23 198 0 / 30%)",
  };

  return (
    <Typography
      fontSize={"small"}
      sx={{
        padding: "1px 10px",
        border: "1px solid gray",
        borderRadius: "5px",
        userSelect: "none",
        ...(isDarkMode ? darkStyles : lightStyles),
      }}
    >
      {text}
    </Typography>
  );
};

const RichFlair = ({
  flair,
  imageFlairSize = "20px",
  ImageFlairStyle = {},
}) => {
  const { e: flairType, t: flairText, u: imageURL } = flair;
  const hasZeroWidthSpace = flairText?.includes("\u200B");

  if (flairType === "text" && flairText?.trim() && !hasZeroWidthSpace)
    return <FlairBadge text={flairText} />;
  if (flairType === "emoji")
    return (
      <>
        <img
          src={imageURL}
          style={{
            width: imageFlairSize,
            pointerEvents: "none",
            ...ImageFlairStyle,
          }}
          draggable={"false"}
        />
      </>
    );
  return <></>;
};
