import { Box } from "@mui/material";
import { ThreadGallery } from "./ThreadGallery";
import { getThreadGallery } from "../../../../helpers/functions";

const getImageURL = (post) => {
  try {
    return post.preview.images[0].source.url;
  } catch (err) {
    return "";
  }
};

const isVideo = (post) => {
  try {
    return post.post_hint === "hosted:video";
  } catch (err) {
    return false;
  }
};
const imageStyle = {
  width: "100%",
  borderRadius: "10px",
};
export const ThreadImage = ({ post }) => {
  const hasImage = post?.preview?.images?.length;
  const imageSrc = getImageURL(post);

  const { media, media_embed, preview } = post;
  const gallery = getThreadGallery(post);

  if (isVideo(post)) {
    return null;
  }
  if (gallery.hasGallery) {
    return <ThreadGallery gallery={gallery} />;
  }
  return (
    <Box sx={{ marginBottom: "13px" }}>
      {!hasImage && post?.thumbnail !== "self" && <img src={post.thumbnail} />}
      {hasImage && (
        <Box>
          <a href={imageSrc} target={"_blank"}>
            <img style={imageStyle} src={imageSrc} />
          </a>
        </Box>
      )}
    </Box>
  );
};

// Hi Tony, I love bothering you :) :-*  2023-07-14 9:36 PM
