import {
  AwardBadge,
  AwardBadgeList,
} from "../../../RedditComponents/Award/AwardBadge/AwardBadge";
import { Box, Fade, Skeleton, Stack, Typography } from "@mui/material";

import { SubBackground } from "../../../RedditComponents/Subreddit/SubBackground/SubBackground";
import { SubIcon } from "../../../RedditComponents/Subreddit/SubIcon/SubIcon";
import { ThreadDomain } from "./ThreadDomain";
import { actions } from "../../../../redux";
import { useDispatch } from "react-redux";
import { SubChip } from "../../../RedditComponents/Subreddit/SubChip/SubChip";
import { styleHelper } from "../../../../styles/styles";
import { updateModal } from "../../../../redux/slices/application.slice";

export const ThreadTitle = ({
  subreddit,
  title,
  isLoading = false,
  awards = [],
  domain,
}) => {
  const dispatch = useDispatch();

  const handleOpenSubModal = () => {
    dispatch(
      updateModal({
        modalId: "subredditModal",
        data: {
          sub: subreddit,
        },
        isOpen: true,
      })
    );
  };
  if (isLoading) {
    return (
      <Box sx={{ position: "relative", width: "100%" }}>
        <Stack sx={{ position: "absolute", top: "20px", right: "10px" }}>
          <Skeleton sx={{ transform: "none" }} width={"35px"} height={"35px"} />
        </Stack>
        <Stack sx={{ position: "absolute", left: "25px", bottom: "10px" }}>
          <Skeleton width={"180px"} height={"35px"} />
          <Skeleton width={"60px"} height={"25px"} />
        </Stack>
        <Skeleton sx={{ transform: "none" }} width={"100%"} height={"138px"} />
      </Box>
    );
  }
  return (
    <Fade in={true}>
      <Box>
        <div className="thread-title-container">
          <SubBackground
            withShadow
            useFallback
            variant={2}
            sub={subreddit}
            className="thread-sub-background"
          />
          <SubIcon
            sx={{
              height: "auto",
              position: "absolute",
              zIndex: 2,
              bottom: 0,
              top: "10px",
              alignItems: "flex-start",
              right: "15px",
              "& img": {
                boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px,
                rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
                backdropFilter: `blur(10px) saturate(1.15)`,
                WebkitBackdropFilter: `blur(10px) saturate(1.15)`,
                background: `rgb(255 255 255 / 25%)`,
              },
            }}
            sub={subreddit}
          />

          <Stack
            sx={{
              zIndex: 4,
              position: "relative",
              color: "rgba(255,255,255,0.75)",
            }}
            gap="10px"
            direction="row"
            alignItems="center"
          >
            <SubChip
              sx={{
                ...styleHelper.blurBackgroundStyles(),
              }}
              sub={subreddit}
              onClick={handleOpenSubModal}
            />
            <ThreadDomain
              domain={
                [`self.${subreddit}`, "reddit.com", "i.redd.it"].includes(
                  domain
                )
                  ? ""
                  : domain
              }
            />
            <AwardBadgeList awards={awards} />
          </Stack>
          <h3 className="thread-title">{title}</h3>
        </div>
      </Box>
    </Fade>
  );
};
