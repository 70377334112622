import { AwardBadgeList } from "../../Award/AwardBadge/AwardBadge";

export const PostAwards = ({ thread }) => {
  const { all_awardings } = thread;
  return (
    <AwardBadgeList
      sx={{
        marginRight: "5px",
        ...(all_awardings.length > 4
          ? {
              minWidth: "100px",
            }
          : {}),
      }}
      awards={all_awardings}
    />
  );
};
