import { Box, IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

export const CloseDrawerButton = ({ onClick, placement = 'right' }) => {
  const left =
    placement === 'left'
      ? {
          right: undefined,
          left: 0,
          borderRadius: '0px 0px 15px 0px',
        }
      : {};

  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
    background: 'rgba(255,255,255,0.5)',
    borderRadius: '0px 0px 0px 15px',
    backdropFilter: 'blur(5px) saturate(160%)',
    boxSizing: 'border-box',
    padding: '5px',
    ...left,
  };
  return (
    <Box sx={style}>
      <IconButton onClick={onClick} sx={{ '&:hover svg': { color: 'white' } }}>
        <Close sx={{ color: 'black' }} />
      </IconButton>
    </Box>
  );
};
