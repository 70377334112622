import { Box, Paper, Stack, Typography } from "@mui/material";

import { SubBackground } from "../../../RedditComponents/Subreddit/SubBackground/SubBackground";
import { actions, selectors } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";

export const HistoryList = ({ onClickPost }) => {
  const history = useSelector(selectors.selectPostHistory);

  return (
    <Paper sx={{ height: "100%", overflow: "scroll" }}>
      <Typography align="center" p="10px">
        {history.length === 0 ? "No posts visited" : `${history.length} Posts`}
      </Typography>
      <Stack>
        {history.map((post, index) => (
          <HistoryItem key={index} post={post} />
        ))}
      </Stack>
    </Paper>
  );
};

const HistoryItem = ({ post }) => {
  const dispatch = useDispatch();

  const handleClickThread = () => {
    dispatch(actions.setActiveThread(JSON.parse(post.post)));
  };

  return (
    <Stack
      onClick={handleClickThread}
      sx={{
        // border: '1px solid gray',
        borderRadius: "9px",
        overflow: "hidden",
        position: "relative",
        marginBottom: "10px",
      }}
    >
      <Stack
        sx={{
          zIndex: 1,
          marginTop: "85px",
          backdropFilter: "blur(10px)",
          background: "rgba(255, 255, 255, 0.5)",
          padding: "10px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Typography fontStyle={"italic"}>r/{post.sub}</Typography>
        <Typography fontWeight={"bold"} variant="caption">
          {post.title}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} variant="caption" fontSize={10}>
          {new Date(post.dateVisited).toLocaleTimeString("en-us")}
        </Typography>
      </Stack>
      <SubBackground
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 0,
        }}
        sub={post.sub}
      />
    </Stack>
  );
};
