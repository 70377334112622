import { JSONModal } from "./JSONModal/JSONModal";
import { ProfileModal } from "./ProfileModal/ProfileModal";
import { AwardModal } from "./AwardModal/AwardModal";
import { CustomRedditURLModal } from "./CustomRedditURLModal/CustomRedditURLModal";
import { WebsiteModal } from "./WebsiteModal/WebsiteModal";
import { SubredditModal } from "./SubredditModal/SubredditModal";

export const RenderAppModals = () => {
  return (
    <>
      <JSONModal />
      <ProfileModal />
      <AwardModal />
      <CustomRedditURLModal />
      <WebsiteModal />
      <SubredditModal />
    </>
  );
};
