import "./HomeView.css";

import { MainContentSidebar } from "../../ApplicationComponents/MainContentSidebar/MainContentSidebar";
import { ErrorMessageDisplay } from "../../ApplicationComponents/ErrorMessageDisplay/ErrorMessageDisplay";
import { FullWidthLoader } from "../../Loaders/FullWidthLoader/FullWidthLoader";
import { Post } from "../../RedditComponents/Post/Post";
import { Stack } from "@mui/material";
import { getHomeFeed } from "../../../api/base";
import { selectors } from "../../../redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { SubIndexerLoader } from "../../Loaders/SubIndexerLoader/SubIndexerLoader";
import { subredditObject } from "../../../data/subreddits";
import { applicationRedux } from "../../../redux/slices/application.slice";
import { fetchSubredditData, wait } from "../../../helpers/functions";
import { selectSubredditIsIndexing } from "../../../redux/selectors/appState.selectors";

export const HomeView = ({ onClickSub, onClickThread }) => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const isIndexingSubs = useSelector(selectors.selectSubredditIsIndexing);

  const isDesktopMode = useSelector(selectors.selectIsDesktopMode);

  const handleFetchPosts = async () => {
    setIsError(false);
    getHomeFeed()
      .then((data) => {
        setPosts(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
      });
  };
  useEffect(() => {
    handleFetchPosts();
  }, []);

  const Container = ({ children }) => (
    <div
      className="home-container"
      style={isDesktopMode ? { paddingBottom: 0 } : {}}
    >
      {children}
    </div>
  );

  const fetchAllLocalSubs = async () => {
    const subArray = Object.keys(subredditObject);

    for (let i = 0; i < subArray.length; i++) {
      const sub = subArray[i];

      console.log("<HomeView> Fetching subreddit: ", sub);
      dispatch(applicationRedux.actions.setCurrentSubToFetch(sub));
      await fetchSubredditData(sub);
      await wait(10);

      const progress = Math.ceil(((i + 1) / subArray.length) * 100);
      dispatch(applicationRedux.actions.setIndexingProgress(progress));
    }
    await wait(100);

    dispatch(applicationRedux.actions.setIsIndexingSubs(false));
  };

  useEffect(() => {
    fetchAllLocalSubs();
  }, []);

  if (isIndexingSubs)
    return (
      <Container>
        <SubIndexerLoader />
      </Container>
    );

  return (
    <Container>
      {isError && (
        <ErrorMessageDisplay
          onRetry={handleFetchPosts}
          message={"Error fetching /home posts"}
        />
      )}
      {isLoading && !isError && <FullWidthLoader />}
      {!isLoading && (
        <Stack
          direction={"row"}
          sx={isDesktopMode ? { overflow: "hidden", height: "100vh" } : {}}
        >
          <ul
            style={
              isDesktopMode
                ? {
                    width: `calc(100% - 350px)`,
                    overflow: "scroll",
                    paddingBottom: "70px",
                  }
                : {}
            }
            className="home-posts-list"
          >
            {posts.map((post, index) => (
              <Post
                key={index}
                showSub={true}
                post={post}
                onClickSub={onClickSub}
                onClickThread={() => onClickThread(post)}
              />
            ))}
          </ul>
          {isDesktopMode && <MainContentSidebar />}
        </Stack>
      )}
    </Container>
  );
};
