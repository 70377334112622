import { Chip, Typography } from "@mui/material";
import { SubIcon } from "../SubIcon/SubIcon";
import { styleHelper } from "../../../../styles/styles";

export const SubChip = ({
  sub,
  onClick = () => {},
  sx = {},
  prefixed = false,
  TypographyProps = {},
  TypographySx = {},
  SubIconProps = {},
  SubIconSx = {},
}) => {
  const prefixedSub = `r/${sub}`;
  return (
    <Chip
      size={"small"}
      sx={sx}
      onClick={onClick}
      label={
        <Typography
          fontSize={"small"}
          sx={{
            userSelect: "none",
            fontFamily: styleHelper.EFontFamily.CoinbaseDisplay,
            color: "#FFF",
            ...TypographySx,
          }}
          fontWeight="bold"
          {...TypographyProps}
        >
          {prefixed ? prefixedSub : sub}
        </Typography>
      }
      avatar={
        <SubIcon
          sub={sub}
          sx={{
            width: "25px",
            ...SubIconSx,
          }}
          ImgProps={{
            style: {
              width: "100%",
              borderRadius: "50%",
            },
          }}
          {...SubIconProps}
        />
      }
    />
  );
};
