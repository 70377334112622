import { Comment } from "@mui/icons-material";

export const PostCommentCount = ({ thread }) => {
  const { num_comments } = thread;

  return (
    <span className="post-num-comments">
      <Comment sx={{ fontSize: "12px", color: "rgb(11, 156, 218)" }} />
      <span className="count">{num_comments} </span>
    </span>
  );
};
