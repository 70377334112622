import { useState } from "react";
import { Button, Modal, Text, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { getCustomThread } from "../../../api/base";
import { Error } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCustomThreadModal,
  updateModal,
} from "../../../redux/slices/application.slice";
import { CardModal } from "../../ApplicationComponents/CardModal/CardModal";
import * as actions from "../../../redux/slices/application.slice";
import { selectModalState } from "../../../redux/selectors/appState.selectors";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { SubChip } from "../../RedditComponents/Subreddit/SubChip/SubChip";

// FIXME: Fix this buggy component when you're not tired
export const CustomRedditURLModal = () => {
  const [isValidURL, setIsValidURL] = useState(true);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isThreadError, setIsThreadError] = useState(false);
  const dispatch = useDispatch();
  const { isOpen, data } = useSelector(selectModalState)["customThreadModal"];

  const onClickThread = (thread) => {
    dispatch(actions.setActiveThread(thread));
  };

  const saveThreadToRecents = (activeThread) => {
    activeThread.__modalURL = url;
    dispatch(
      updateModal({
        modalId: "customThreadModal",
        data: data ? [...data, activeThread] : [activeThread],
        isOpen: false,
      })
    );
  };

  const handleType = ({ currentTarget: { value } }) => {
    setUrl(value);
    setIsThreadError(false);
  };
  const handleOpenThread = () => {
    setIsThreadError(false);
    const apiURL = getApiURL(url);
    setIsLoading(true);

    getCustomThread(apiURL)
      .then((activeThread) => {
        if (activeThread) {
          saveThreadToRecents(activeThread);
          onClickThread(activeThread);
        } else {
          setIsThreadError(true);
        }
      })
      .catch((err) => {
        setIsThreadError(true);
      });
  };

  useEffect(() => {
    setIsLoading(false);
    setUrl("");
    setIsValidURL(true);
    setIsThreadError(false);
  }, [isOpen]);

  useEffect(() => {
    setIsLoading(false);
  }, [isThreadError]);

  useEffect(() => {
    setIsValidURL(isValidRedditUrl(url));
  }, [url]);

  return (
    <CardModal modalId={"customThreadModal"} sheet>
      <Typography variant={"h6"} align={"center"}>
        Open Reddit Link
      </Typography>
      <Stack>
        {data && (
          <>
            <Typography>Recent:</Typography>
            <RecentThreads
              threads={data}
              onClickThread={(thread) => {
                setUrl(thread.__modalURL);
                handleOpenThread();
              }}
            />
          </>
        )}
      </Stack>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <TextInput
          error={
            isValidURL || !url
              ? undefined
              : "This doesn't look like a valid reddit URL"
          }
          onChange={handleType}
          label="Paste URL"
          value={url}
          disabled={isLoading}
        />
        {isThreadError && (
          <Text
            color={"red"}
            size="xs"
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              gap: "5px",
            }}
            align="center"
          >
            <Error sx={{ fontSize: "15px" }} /> Error Fetching Thread Object
          </Text>
        )}
        <Button
          loading={isLoading}
          onClick={handleOpenThread}
          disabled={!isValidURL || !url}
          fullWidth
        >
          Open Thread
        </Button>
      </Box>
    </CardModal>
  );
};
const domains = [
  "old.reddit.com",
  "www.reddit.com",
  "libreddit.com",
  "np.reddit.com",
];

const isValidRedditUrl = (url) => {
  for (const domain of domains) {
    if (url.startsWith(`https://${domain}`)) return true;
  }
  return false;
};

const getApiURL = (url) => {
  // "https://hosteddatabase-production.up.railway.app/reddit/r";
  let apiURL = "";

  for (const domain of domains) {
    const _d = `https://${domain}`;
    if (url.startsWith(_d)) {
      apiURL = url.replace(_d, "https://api.anthony.media/reddit/api");
    }
  }
  return apiURL;
};

const RecentThreads = ({ threads, onClickThread }) => {
  return (
    <Stack gap={"5px"} mb={"20px"}>
      {threads.map((thread, index) => (
        <Stack
          gap={"10px"}
          direction={"row"}
          onClick={() => onClickThread(thread)}
          key={index}
        >
          <SubChip sub={thread.subreddit} />
          <Typography noWrap>{thread.title}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
