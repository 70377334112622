import axios from "axios";
import { BASE_API_URL, CONSTANTS } from "../config";
import { parseCustomThread } from "../helpers/functions";

export const getSubreddit = async (sub) => {
  const { data } = await axios.get(`${BASE_API_URL}/r/${sub}`);
  return data;
};

export const getThread = async (permalink) => {
  const { data } = await axios.get(permalink);
  return data;
};

export const getHomeFeed = async () => {
  const { data } = await axios.get(`${BASE_API_URL}/home`);
  return data;
};

export const getCustomThread = async (threadDataLink) => {
  const { data } = await axios.get(threadDataLink);

  const resolvedData = parseCustomThread(data);
  const { permalink } = resolvedData;

  resolvedData["permalink"] = `${BASE_API_URL}${permalink}`;
  return resolvedData;
};

export const getOpenGraphData = async (url) => {
  const { data } = await axios.get(`${BASE_API_URL}/opengraph?url=${url}`);
  return data;
};

export const getSubredditAboutJSON = async (sub) => {
  const { data } = await axios.get(`${BASE_API_URL}/r/${sub}/about`);
  return data;
};

export const getSubredditMetaData = async (sub) => {
  const subName = sub.toLowerCase();

  const cdnURL = `${CONSTANTS.STATIC_SUB_JSON_URL}/${subName}.json`;
  const apiURL = `${BASE_API_URL}/r/${subName}/metadata`;

  try {
    const { data } = await axios.get(cdnURL);

    return data;
  } catch (err) {
    // Try the live data
    try {
      const { data } = await axios.get(apiURL);

      return data;
    } catch (err) {
      return false;
    }
  }
};

export const getSimilarSubreddits = async (sub) => {
  const { data } = await axios.get(`${BASE_API_URL}/r/${sub}/similar`);
  return data;
};
